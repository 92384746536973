import {
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  REMOVE_CART_ITEM_SUCCESS,
  SHIPPING_RATE_REQUEST,
  SHIPPING_RATE_SUCCESS
} from "./types";
import { logout,signInAction } from "./authActions";
import hillsServer from "../api/hillsServer";
import { showSuccessSnackbar, showErrorSnackbar } from "./messageAction";
import axios from 'axios';

//const calcCartWeightSum = (CartWeight) => {
//  var sum = 0;
//  if (parseInt(CartWeight) >= 1000 && parseInt(CartWeight) < 5000) {
//    sum = 50;
//    let cartNowWeight = parseInt(CartWeight - 1000);
//
//    if (cartNowWeight !== 0) {
//      sum += parseInt((cartNowWeight / 500) * 20);
//    }
//  } else if (CartWeight !== 0) {
//    sum = 50;
//    let cartNowWeight = parseInt(CartWeight);
//    sum += (cartNowWeight / 1000) * 15;
//  }
//  return sum;
//};


function myOrdersSetup(RespData) {
  let response = RespData;
  let productMap = new Map();
  let product;
  for (let j = 0; j < response.length; j++) {
    let arrayOfProduct = response[j].products;
    for (let i = 0; i < arrayOfProduct.length; ) {
      if (productMap.has(arrayOfProduct[i]._id)) {
        product = productMap.get(arrayOfProduct[i]._id);
        product.itemDuplicates = (product.itemDuplicates || 1) + 1;
        arrayOfProduct.splice(i, 1);
      } else {
        arrayOfProduct[i].itemDuplicates = 1;
        productMap.set(arrayOfProduct[i]._id, arrayOfProduct[i]);
        i++;
      }
    }
    productMap = null;
    productMap = new Map();
  }
  return response;
}

export const getCartItems = () => {
  return async (dispatch) => {
    dispatch({ type: GET_CART_REQUEST });
    try {
      const res = await hillsServer.get("/users/getItemsToCart");
      const arrayOfCartItems = res.data.data.user;
      console.log(res);
      if (arrayOfCartItems) {
        var productMap = new Map();
        var product;
        for (var i = 0; i < arrayOfCartItems.length; ) {
          if (productMap.has(arrayOfCartItems[i]._id)) {
            product = productMap.get(arrayOfCartItems[i]._id);
            product.quantity = (product.quantity || 1) + 1;
            arrayOfCartItems.splice(i, 1);
          } else {
            arrayOfCartItems[i].quantity = 1;
            productMap.set(arrayOfCartItems[i]._id, arrayOfCartItems[i]);
            i++;
          }
        }
        var sumTotal = 0;
        var cartWeightTotal=0;
        for (var j = 0; j < arrayOfCartItems.length; j++) {
          sumTotal += arrayOfCartItems[j].quantity * arrayOfCartItems[j].price;
          cartWeightTotal += parseInt(
            arrayOfCartItems[j].weight * arrayOfCartItems[j].quantity
          );
          console.log(cartWeightTotal);
        }
      }
      console.log(cartWeightTotal);
      cartWeightTotal=cartWeightTotal/1000;
      const orderdata = myOrdersSetup(res.data.data.myOrders);
      const amount=sumTotal;
      dispatch({
        type: GET_CART_SUCCESS,
        payload: {
          cart: res.data.data.user,
          sumTotal,
          amount,
          cartWeightTotal,
          myorders: orderdata,
          cartlen: res.data.data.user.length,
        },
      });
    } catch (e) {
      dispatch(logout());
    }
  };
};

export const addToCart = (productId) => {
  return async (dispatch) => {
    try {
      const res = await hillsServer.patch("/users/addItemsToCart", {
        cart: productId,
      });
      if (res.data.status === "success") {
        await dispatch(getCartItems());
        dispatch(showSuccessSnackbar("Item Added to cart successfully"));
      }
    } catch(e) {
      dispatch(showErrorSnackbar(e.message));
    }
  };
};

export const delCartItem = (productId) => {
  return async (dispatch) => {
    try {
      const res = await hillsServer.post("/users/removeCartItem", {
        productId: productId,
      });
      if (res.data.status === "success") {
        dispatch({ type: REMOVE_CART_ITEM_SUCCESS, payload: productId });
        dispatch(showSuccessSnackbar("Item Removed from cart successfully"));
        await dispatch(getCartItems());        
      }
    } catch(e) {
      dispatch(showErrorSnackbar(e.message));
    }
  };
};
