import React from "react";

function Overlay({ setExpanded, expanded, forget, setForget }) {
  return (
    <div className="overlay-container">
      <div className="overlay">
        <div className="overlay-panel overlay-left">
          <h1>Already a member ?</h1>
          <p className="overlay-description">Please Sign In</p>
          <button
            className="ghost form-button overlay-button"
            onClick={() => {
              !forget ? setExpanded(!expanded) : setForget(!forget);
            }}
          >
            Sign In
          </button>
        </div>
        <div className="overlay-panel overlay-right">
          <h1>Not a Member ?</h1>
          <p className="overlay-description">
            Please Sign Up <br />
            to continue
          </p>
          <button
            className="ghost form-button overlay-button"
            onClick={() => setExpanded(!expanded)}
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
}

export default Overlay;
