import React from "react";
// components
import ContactForm from "./form/form";
import Address from "./address/address";
import hillsServerApi from "../../api/hillsServer";
import {
  ResponsiveEmbed,
  Col,
  Row,
  // Container,
  Button,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { showSuccessSnackbar, showErrorSnackbar } from "../../actions";
import "./contact.css";

const Contact = (props) => {
  const dispatch = useDispatch();

  const scrollToTop = () => {
    const y = document.documentElement.scrollTop || document.body.scrollTop;
    if (y > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, y - y / 10);
    }
  };
  const handleOnSubmit = async (emailObject) => {
    try {
      const response = await hillsServerApi.post(
        "/email/newsLetter",
        emailObject
      );
      if (response.data.status === "success") {
        dispatch(showSuccessSnackbar("Email Sent Successfully"));
      }
    } catch (e) {
      dispatch(showErrorSnackbar("Please enter the information"));
    }
  };
  return (
    <>
      <div id="contact" className="contact my-4 px-4">
        <br />
        {/*<Container>*/}
        {/* Bact to top button */}
        {props.btn && (
          <Row className="back-button-footer">
            <Button
              onClick={(e) => {
                scrollToTop();
              }}
              variant="success"
              block
            >
              Back To Top
              </Button>
          </Row>
        )}
        <div className="contact-heading justify-content-center p-2">
          <h2>Getting Stronger On Our Mission</h2>
          <p>Our objective at Hills2home is to connect the hills of Uttarakhand with the markets across the country.The unexplored organic products of Uttarakhand are a boon for our body and immune systems.We provide an e-commerce platform to the farmers in the hills to sell their produce raw/ or in the form of finished products through our processing, with ease.</p>
          
        </div>
        <Row className="justify-content-center">
          <Col lg={4} sm={12} xs={12} md={10} className="contact-col">
            {/* <h4 className="contact-heading text-center">Contact Us</h4> */}
            <ContactForm handleOnSubmit={handleOnSubmit} />
          </Col>
          <Col lg={5} sm={12} xs={12} md={10} className="contact-col">
            <Address />
          </Col>
          <Col lg={3} sm={12} xs={12} md={10} className="contact-col">
            <ResponsiveEmbed className="shadow" aspectRatio="16by9">
              <embed src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13777.085790078476!2d77.98825613033675!3d30.31479585855415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39092a3e1e26ef77%3A0x2f22f287a6ed7e33!2sIndra%20Nagar%20Colony%2C%20Dehradun%2C%20Uttarakhand!5e0!3m2!1sen!2sin!4v1599289037714!5m2!1sen!2sin" />
            </ResponsiveEmbed>
          </Col>
        </Row>
        {/*</Container>*/}
      </div>
    </>
  );
};

export default Contact;