import {
  GET_USER_ADDRESS_REQUEST,
  GET_USER_ADDRESS_SUCCESS,
  ADD_USER_ADDRESS_SUCCESS,
  ADD_DELIVERY_ADDRESS_SUCCESS,
  RESET_USER,
} from "../actions/types";

const initState = {
  address: [],
  cartAddress: [],
  loading: false,
};

const userReducers = (state = initState, action) => {
  switch (action.type) {
   
    case GET_USER_ADDRESS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
      
    case ADD_USER_ADDRESS_SUCCESS:
    case GET_USER_ADDRESS_SUCCESS: {
      return {
        ...state,
        address: action.payload,
        loading: false,
      };
    }

    case ADD_DELIVERY_ADDRESS_SUCCESS: {
      return {
        ...state,
        cartAddress: action.payload,
        loading: false,
      };
    }

    case RESET_USER: {
      return { ...initState };
    }
    default:
      return state;
  }
};

export default userReducers;
