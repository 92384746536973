import React from "react";
import FaqTemplate from "./faq";
import { FaqData } from "./data";
import {TabPane} from 'reactstrap';

function Faq() {
  return (
    <TabPane tabId="7">
      <FaqTemplate data={FaqData} />
    </TabPane>
  );
}
export default Faq;
