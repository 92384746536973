import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import DropDownMenu from "./dropDownMenu";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import "./navbar.css";
import { FaShoppingCart } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { logout } from "../../actions";

// Logo Image
import HomeLogo from "../../assets/home-logo.png";
import LionLogo from "../../assets/logo-lion.png";

function NavBar(props) {
  const auth = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const activeStyle = {
    color: "white",
  };

  const [expanded, setExpanded] = useState(false);
  const [menuOpen, toggleMenuOpen] = useState(false);
  const handleOnClick = () => setExpanded(false);

  return (
    <>
      <header>
        <div>
          <Navbar
            collapseOnSelect
            expand="lg"
            className="bottom-nav justify-content-center"
            expanded={expanded}
          >
            <div className="home-logo mr-auto">
              <Link to="/">
                <img src={HomeLogo} width="59px" height="59px" alt="" />
              </Link>
            </div>
            <span className="w-50 mr-auto">
              <h4 className="mb-0 offset-3 home-text">
                "PURITY IS JUST A CLICK AWAY"
              </h4>
            </span>

            <Nav className="d-lg-none d-flex flex-row">
              <Nav.Item>
                {/* Renders CartIcon in the navbar on mobile view alongside of menubar*/}
                <NavLink
                  to="/shop"
                  activeStyle={activeStyle}
                  className="nav-link"
                >
                  Shop
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                {/* Renders CartIcon in the navbar on mobile view alongside of menubar*/}
                <NavLink
                  to="/cart"
                  activeStyle={activeStyle}
                  className="nav-link"
                >
                  <span
                    style={{ position: "relative" }}
                    className="cart-basket"
                    datacount={cart.cartLength}
                  >
                    <FaShoppingCart />
                  </span>
                </NavLink>
              </Nav.Item>
            </Nav>

            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              onClick={() => setExpanded(!expanded)}
            />
            <Navbar.Collapse id="responsive-navbar-nav" className="w-100">
              <Nav className="w-100 justify-content-center hover-border">
                <Nav.Item onClick={handleOnClick}>
                  <NavLink
                    to="/"
                    exact
                    activeStyle={activeStyle}
                    className="nav-link"
                  >
                    Home
                  </NavLink>
                </Nav.Item>
                <Nav.Item onClick={handleOnClick}>
                  <NavLink
                    to="/accreditation"
                    activeStyle={activeStyle}
                    className="nav-link"
                  >
                    Accreditation
                  </NavLink>
                </Nav.Item>

                <Nav.Item onClick={handleOnClick}>
                  <NavLink
                    to="/blog"
                    activeStyle={activeStyle}
                    className="nav-link"
                  >
                    Blog
                  </NavLink>
                </Nav.Item>
                <NavDropdown
                  onMouseEnter={() => {
                    toggleMenuOpen(true);
                  }}
                  onMouseLeave={() => {
                    toggleMenuOpen(false);
                  }}
                  show={menuOpen}
                  title="Shop"
                  id="collasible-nav-dropdown"
                >
                  <NavLink
                    to="/shop"
                    exact
                    className="dropdown-item"
                    onClick={handleOnClick}
                    activeStyle={activeStyle}
                  >
                    Hills2Home Exclusive
                  </NavLink>
                  <NavLink
                    to="/shop/ngo"
                    activeStyle={activeStyle}
                    className="dropdown-item"
                    onClick={handleOnClick}
                  >
                    NGO/Farmers Products
                  </NavLink>
                </NavDropdown>
                <Nav.Item onClick={handleOnClick}>
                  <NavLink
                    to="/contact"
                    activeStyle={activeStyle}
                    className="nav-link"
                  >
                    Contact
                  </NavLink>
                </Nav.Item>
              </Nav>

              <Nav className="navbar-nav w-100 ">
              <Nav.Item className="d-none d-lg-block text-center">
                  <NavLink
                    to="/cart"
                    activeStyle={activeStyle}
                    className="nav-link"
                  >
                    <span
                      style={{ position: "relative" }}
                      className="cart-basket"
                      datacount={cart.cartLength}
                    >
                      <FaShoppingCart />
                    </span>
                    <span className="card-word">Cart</span>
                  </NavLink>
                </Nav.Item>
                {auth.isauthenticated ? (
                  // <DropDownMenu
                    // title={auth.user.name.toUpperCase()}
                    // handleOnClick={handleOnClick}
                  // />
                  <Nav.Item onClick={handleOnClick}>
                    <NavLink
                      to="/dashboard"
                      activeStyle={activeStyle}
                      className="nav-link"
                    >
                      <AccountCircleIcon/>
                      <span>{auth.user.name}</span>
                    </NavLink>
                  </Nav.Item>
                ) : (
                  <Nav.Item onClick={handleOnClick}>
                    <NavLink
                      to="/authenticate"
                      activeStyle={activeStyle}
                      className="nav-link"
                    >
                      Login
                    </NavLink>
                  </Nav.Item>
                )}       
                {/* <Nav.Item  onClick={() => {dispatch(logout()); handleOnClick(); }}> */}
                    {/* Logout */}
                {/* </Nav.Item>          */}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
        <div className="lion-logo">
          <a href="https://www.makeinindia.com/">
            <img alt="" src={LionLogo} width="85" height="40" />
          </a>
          <div id="lionshadow"></div>
        </div>
      </header>
    </>
  );
}

export default NavBar;
