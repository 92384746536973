export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";

export const GET_USER_ADDRESS_REQUEST = "GET_USER_ADDRESS_REQUEST";
export const GET_USER_ADDRESS_SUCCESS = "GET_USER_ADDRESS_SUCCESS";
export const ADD_DELIVERY_ADDRESS_SUCCESS = "ADD_DELIVERY_ADDRESS_SUCCESS";
export const ADD_USER_ADDRESS_SUCCESS = "ADD_USER_ADDRESS_SUCCESS";

export const GET_CART_REQUEST = "GET_CART_REQUEST";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const SHIPPING_RATE_REQUEST = "SHIPPING_RATE_REQUEST";
export const SHIPPING_RATE_SUCCESS = "SHIPPING_RATE_SUCCESS";
export const SHIPROCKET_SIGNIN_SUCCESS = "SHIPROCKET_SIGNIN_SUCCESS";



export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";

export const SHOW_CATEGORY = "SHOW_CATEGORY";
export const ADD_REVIEW_SUCCESS = "ADD_REVIEW_SUCCESS";

export const SHOW_SUCCESS_MESSAGE = "SHOW_SUCCESS_MESSAGE";
export const SHOW_ERROR_MESSAGE = "SHOW_ERROR_MESSAGE";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";

export const RESET_CART = "RESET_CART";
export const RESET_USER = "RESET_USER";

export const GET_BLOG_REQUEST = "GET_BLOG_REQUEST";
export const GET_BLOG_SUCCESS = "GET_BLOG_SUCCESS";

export const GET_BLOG_DATA_REQUEST = "GET_BLOG_DATA_REQUEST";
export const GET_BLOG_DATA_SUCCESS = "GET_BLOG_DATA_SUCCESS";

export const REMOVE_CART_ITEM_SUCCESS = "REMOVE_CART_ITEM_SUCCESS";

export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_DATA_REQUEST = "GET_CATEGORY_DATA_REQUEST";
export const GET_CATEGORY_DATA_SUCCESS = "GET_CATEGORY_DATA_SUCCESS"


export const GET_COUPON_REQUEST = "GET_COUPON_REQUEST";
export const GET_COUPON_SUCCESS = "GET_COUPON_SUCCESS";
export const GET_COUPON_DATA_REQUEST = "GET_COUPON_DATA_REQUEST";
export const GET_COUPON_DATA_SUCCESS = "GET_COUPON_DATA_SUCCESS"