import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import ShowLoading from "../../components/Loading";
import { getBlogById } from "../../actions";
import { Footer } from "../../components/Footer";


function BlogPage(props) {
  const id = props.match.params.id;
  const { getBlogById, blog, isloading } = props;
  useEffect(() => {
    getBlogById(id);
  }, [getBlogById, id]);

  const style = {
    backgroundImage: `url(
      data:${blog.img && blog.img.contentType}};base64,${
      blog.img && blog.img.data
    }
    )`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",

    height: "350px",
    overflow: "hidden",
  };
  return (
    <>
    <div className="mb-5">
      <Container style={{ minHeight: "80vh" }}>
        {isloading ? (
          <ShowLoading />
        ) : (
          <div>
            <h3 className="text-center p-4">{blog.title}</h3>
            <div style={style} className="text-center"></div>
            <h6 className="mt-4">Hello Folks!</h6>
            <h6 className="mt-4">{blog.note}</h6>
            <h6 className="mt-4">Happy Reading !</h6>
            {blog.content &&
              blog.content.split("\n").map((item, i) => (
                <p className="mt-4" key={i}>
                  {item}
                </p>
              ))}
          </div>
        )}
      </Container>
    </div>
    <Footer />
    </>    
  );
}

const mapStateToProps = (state) => {
  return {
    blog: state.blog.blog || {},
    isloading: state.blog.loading,
  };
};

export default connect(mapStateToProps, { getBlogById })(BlogPage);
