import {
  LOGIN_SUCCESS,
  SIGNUP_SUCCESS,
  LOGOUT_SUCCESS,
  RESET_CART,
  RESET_USER,
  GET_USER_ADDRESS_SUCCESS,
  ADD_DELIVERY_ADDRESS_SUCCESS,
  SHIPROCKET_SIGNIN_SUCCESS,
} from "./types";

import hillsServer from "../api/hillsServer";
import history from "../config/history";
import { getCartItems } from "./cartActions";
import { showSuccessSnackbar, showErrorSnackbar } from "./messageAction";
import jwt from "jsonwebtoken"


export const signUpAction = (authDetails) => {
  return async (dispatch) => {
    
    try {
      const res = await hillsServer.post("/users/signup", authDetails);
      console.log(res.data);
      if (res.data.status === "success") {
        history.push("/");
        const { user } = res.data.data;
        const { token } = res.data;

        localStorage.setItem("token", token);
        hillsServer.defaults.headers.common["Authorization"] = token;
        localStorage.setItem("user", JSON.stringify(user));

        dispatch({
          type: SIGNUP_SUCCESS,
          payload: { user, token },
        });
        dispatch(showSuccessSnackbar("User Registered Successfully"));
        dispatch(getCartItems());
      }
    } catch (error) {
      dispatch(showErrorSnackbar(error.message));
    }
  };
};

export const signInAction = (user) => {
  return async (dispatch) => {
   
    try {
      const res = await hillsServer.post("/users/login", user);
      if (res.data.status === "success") {
        history.push("/");
        const { user } = res.data.data;
        const { token } = res.data;
        localStorage.setItem("token", token);
        hillsServer.defaults.headers.common["Authorization"] = token;
        localStorage.setItem("user", JSON.stringify(user));

        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user, token },
        });
        dispatch(getCartItems());
      }
    } catch (error) {
      dispatch(showErrorSnackbar("Incorrect email or password"));
    }
  };
};


export const signInShiprocket = () => {
  return async (dispatch) => {
    try {
      const response = await hillsServer.post("/users/shipment/login");
      if (response) {
        localStorage.setItem("shiprocketToken", response.data.token);
        console.log(response.data);
        dispatch({
          type: SHIPROCKET_SIGNIN_SUCCESS,
        });
        //dispatch(showSuccessSnackbar("Shiprocket login Success"));
      }
    } catch (error) {
      dispatch(showErrorSnackbar("Shiprocket login Fail."));
    }
  };
};

export const logout = () => {
  return (dispatch) => {
    localStorage.clear();
    delete hillsServer.defaults.headers.common["Authorization"];
    dispatch({ type: LOGOUT_SUCCESS });
    dispatch({ type: RESET_CART });
    dispatch({ type: RESET_USER });
  };
};

export const forgetPassword = (resetPasswordEmail) => {
  return async (dispatch) => {
    try {
      const res = await hillsServer.post("/users/forgotPassword", {
        email: resetPasswordEmail,
      });
      if (res.data.status === "success") {
        dispatch(showSuccessSnackbar("Password Reset Link has been sent successfully"));
        history.push("/authenticate");
      }
    } catch (error) {
      dispatch(showErrorSnackbar("This Email doesn't exist"));
    }
  };
};

export const resetPassword = (authDetails) => {
  return async (dispatch) => {
    try {
      const res = await hillsServer.patch(
        `/users/resetPassword/${authDetails.token}`,
        authDetails
      );
      if (res.data.status === "success") {
        dispatch(showSuccessSnackbar("Password has been changed successfully Try to login"));
        history.push("/authenticate");        
      }
    } catch (error) {
      dispatch(showErrorSnackbar("Token is invalid or has expired"));
    }
  };
};

export const isUserLoggedIn = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      hillsServer.defaults.headers.common["Authorization"] = token;
      const user = JSON.parse(localStorage.getItem("user"));
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          token,
          user,
        },
      });
      dispatch({ type: GET_USER_ADDRESS_SUCCESS, payload: user.address });
      dispatch({
        type: ADD_DELIVERY_ADDRESS_SUCCESS,
        payload: user.cartAddress,
      });
      dispatch(getCartItems());
    }
    // else {
    //   dispatch({
    //     type: LOGIN_FAILURE,
    //     payload: { error: "Failed to login" },
    //   });
    // }
  };
};
