import React from "react";
import RefundTemplate from "./refund";
import { RefundQ } from "./data";
import { Container } from "react-bootstrap";
import {TabPane} from 'reactstrap';

function Refund() {
  return (
    <>
    <TabPane tabId="5">
      <Container>
        <RefundTemplate data={RefundQ} />
      </Container>
    </TabPane>
    </>
  );
}

export default Refund;
