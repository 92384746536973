import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import ReactStars from "react-rating-stars-component";
import ShowLoading from "../../components/Loading";
import {
  Tabs,
  Container,
  Row,
  Table,
  Col,
  Tab,
  Card,
  Carousel,
  Image,
} from "react-bootstrap";
import { Footer } from "../../components/Footer";
import {
  addToCart,
  getProductById,
  addReviewAction,
  showErrorSnackbar,
} from "../../actions";
import { FaShoppingCart } from "react-icons/fa";
import { FiLogIn } from "react-icons/fi";
import { MdStar } from "react-icons/md";
import { MdRateReview } from "react-icons/md";
import { VscAccount } from "react-icons/vsc";

import "./product.css";

function Product(props) {
  const {
    getProductById,
    addToCart,
    showErrorSnackbar,
    addReviewAction,
  } = props;
  const id = props.match.params.id;

  useEffect(() => {
    getProductById(id);
  }, [getProductById, id]);

  var { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [key, setKey] = useState("materials");
  const [userReview, setUserReview] = useState("");
  const [userRating, setUserRating] = useState(0);

  const handleOnChangeReview = (event) => {
    setUserReview(event.target.value);
  };

  const handleOnClick = (event) => {
    if (props.isLogged) {
      // call the action creater
      addToCart(props.product._id);
    } else {
      props.history.push("/authenticate");
    }
  };

  const reviewLength = props.product.review ? props.product.review.length : 0;

  const addReview = async () => {
    let Review = {};
    Review.userName = user.name;
    Review.userReview = userReview;
    Review.userScore = userRating;
    if (Review.userReview) {
      dispatch(addReviewAction(Review, id));
    } else {
      dispatch(showErrorSnackbar("Please enter your review"));
    }
  };

  const renderDetails = (product) => {
    return (
      <>
        <div className="card-body">
          <h3 className="font-weight-bold">{product.title}</h3>
          <Row className="align-items-center mt-2 mb-2">
            <div className="d-flex btn btn-success">
              <span>{Math.ceil(product.averageReviewScore)}</span>
              <MdStar className="ml-2" size="25" />
            </div>
            <h4 className="m-0 ml-2 ml-xs-0">
              {reviewLength} Ratings &amp; {reviewLength} Reviews
            </h4>
          </Row>

          <Row className="font-weight-bold">
            <h3>₹ {product.price}</h3>
          </Row>
          <Row>
            <p>{product.description}</p>
          </Row>
          <Row>
            <button
              style={{
                backgroundColor: "#2d3753",
                color: "white",
                borderColor: "#2d3753",
              }}
              onClick={handleOnClick}
              className="btn"
            >
              <FaShoppingCart /> Add To Cart
            </button>
          </Row>
        </div>
      </>
    );
  };

  const renderProductInfo = (product) => {
    return (
      <Row>
        <Table striped className="text-left font-weight-light text-monospace">
          <tbody>
            <tr>
              <td>Brand Name</td>
              <td>{product.brandName}</td>
            </tr>
            <tr>
              <td>Flavor</td>
              <td>{product.flavour}</td>
            </tr>
            <tr>
              <td>MRP</td>
              <td>₹{parseInt(product.price) + parseInt(product.gstTax)}</td>
            </tr>
            <tr>
              <td>Basic Price</td>
              <td>₹{product.price}</td>
            </tr>
            <tr>
              <td>Weight/Volume in Gms</td>
              <td>{product.weight}g</td>
            </tr>

            <tr>
              <td>Maufactured By</td>
              <td>{product.manufacturedBy}</td>
            </tr>
            <tr>
              <td>GSTIN</td>
              <td>{product.gstNumber}</td>
            </tr>

            <tr>
              <td>Origin</td>
              <td>{product.origin}</td>
            </tr>

            <tr>
              <td>Shelf Life</td>
              <td>{product.shelflife} days</td>
            </tr>
          </tbody>
        </Table>
      </Row>
    );
  };
  const renderReview = (product) => {
    return (
      <>
        {product.review.length > 0 &&
          product.review.map((eachReview) => {
            return (
              <Row key={eachReview._id}>
                <Col>
                  <Card>
                    <Card.Header>
                      <VscAccount size="40" />
                      <span className="ml-3">{eachReview.userName}</span>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>{eachReview.userReview}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            );
          })}
      </>
    );
  };

  const renderReviewInput = () => {
    return (
      <Row className="align-items-center">
        <Col xs={12}>
          <ReactStars
            count={5}
            activeColor="#ffd700"
            size={30}
            onChange={(value) => {
              setUserRating(value);
            }}
          />
        </Col>
        <Col xs={12} className="p-0">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              addReview();
            }}
          >
            <Col xs={12}>
              <input
                name="review"
                type="text"
                className="w-100"
                value={userReview}
                placeholder="Enter Your Review"
                onChange={handleOnChangeReview}
              ></input>
            </Col>
            <Col>
              <button className="mt-2 btn btn-primary text-center">
                Submit
              </button>
            </Col>
          </form>
        </Col>
      </Row>
    );
  };

  const renderImages = (photo) => {
    return (
      <Carousel className="shadow-sm">
        {photo.map((url, index) => {
          return (
            <Carousel.Item key={index}>
              <Image
                fluid
                height="450px"
                className="d-block"
                src={url}
                alt="Not available"
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    );
  };

  return (
    <>
    <Container className="product">
      {props.isloading ? (
        <ShowLoading />
      ) : (
        <>
          <Row>
            <Col
              xs={12}
              md={5}
              sm={5}
              className="align-self-center justify-content-center"
              style={{ minHeight: "300px" }}
            >
              {/* This to render the images in carousel */}
              {props.photos.length > 0 && renderImages(props.photos)}
            </Col>

            <Col xs={12} md={7} sm={7}>
              {renderDetails(props.product)}
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                variant="pills"
                onSelect={(k) => setKey(k)}
              >
                <Tab
                  key={props.product._id}
                  eventKey="materials"
                  title="MATERIALS"
                >
                  <div className="pt-3">{renderProductInfo(props.product)}</div>
                </Tab>
                <Tab eventKey="review" title="REVIEWS">
                  <div className="pt-3">
                    {props.product.review && renderReview(props.product)}
                    {props.isLogged ? (
                      <>
                        {/* {props.reviewAlreadyExists && renderReview(props.product)} */}

                        {props.reviewAlreadyExists ? (
                          <Row>
                            <Col>
                              <p className="mt-3">
                                <MdRateReview size="30" />
                                <span className="ml-1 ">
                                  You Have Already Posted A Review
                                </span>
                              </p>
                            </Col>
                          </Row>
                        ) : (
                          renderReviewInput()
                        )}
                      </>
                    ) : (
                      <Row className="align-items-center justify-content-between mt-5 bg-light">
                        <p className="m-0">
                          <FiLogIn color="f78012" size="35" />
                          <span className="ml-1 ml-xs-0">
                            Sign In to Post Reviews About the Product
                          </span>
                        </p>
                        <Link
                          to="/authenticate"
                          className="btn btn-outline-info"
                        >
                          Sign In / Sign
                        </Link>
                      </Row>
                    )}
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </>
      )}
    </Container>
    <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    product: state.product.productDetails,
    isLogged: state.auth.isauthenticated,
    reviewAlreadyExists: state.product.reviewAlreadyExists,
    isloading: state.product.loading,
    photos: state.product.productDetails.photo || [],
  };
};
export default connect(mapStateToProps, {
  addToCart,
  getProductById,
  addReviewAction,
  showErrorSnackbar,
})(Product);
