import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Badge,Button } from 'reactstrap';

function BlogTemplate({ blog }) {
  const style = {
    backgroundImage: `url(
      data:${blog.img && blog.img.contentType}};base64,${
      blog.img && blog.img.data
    }
    )`,
    borderRadius: "4px",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "260px",
    overflow: "hidden",
  };
  return (
    <Card className="mb-4" style={{ borderRadius: "4px" }}>
      <div style={style} className="text-center"></div>
        <Col>
          <div className="py-1">
          <h6><Badge color="success" pill style={{}}>5 Min Read</Badge></h6>
            <h5 style={{marginTop:"20px"}}>{blog.title}</h5>
          </div>
          <div className="py-1">
            <p>
              {blog.note}
              <br />
              <br />
            </p>
          </div>
          <Button color="success" style={{ borderRadius: "14px",color:"white"}}>
          <Link style={{color:"white"}}
            className="float-right m-0 p-0 btn"
            to={`/blog/${blog._id}`}
          >
            Read More 
          </Link>
          </Button>
          <br /><br />
        </Col>
    </Card>
  );
}

export default BlogTemplate;
