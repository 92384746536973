import React from "react";
import "./social.css";
import { Row, Col, Container } from "react-bootstrap";
import social from "../../../assets/social/social.png";
import { CenterFocusStrongOutlined } from "@material-ui/icons";

function SocialImpact() {
  return (
    <Container>
    <Row className="mx-auto w-50 text-center" style={{ paddingLeft: "12%" }}>
      <h1 className="headingsocial" style={{ zIndex: "1" }}>Social Impact</h1>
    </Row>
    <Row>
      <Col lg={5} xs={12} sm={12} md={12} className="align-self-center">
        <div className="text-center">
          <img src={social} className="Img img-responsive imgsocial" alt="social" />
        </div>
      </Col>
      <Col lg={7} xs={12} md={12} sm={12} className="text-justify">
        <p>On an educational level, we aspire to create a knowledge base to improve the efficiency of agriculture in the hills by educating farmers about the modern techniques of farming and related business concepts.</p>
        <p>About 100 partner farmers are associated with us who have immensely benefited from the
        sales of our products during these challenging times of the coronavirus pandemic.</p>
        <p>In pursuit of our goals, we have been recognised amongst the top 100 startups for social
        development in India by IIM Calcutta Innovation Park in partnership with Department of Science
        and Technology – Government of India in the esteemed Smart Fifty Contest.</p>      
      </Col>
    </Row>    
  </Container>
  );
}

export default SocialImpact;
