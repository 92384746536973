import React from "react";
import { Card } from "react-bootstrap";

function AddressCard(props) {
  return (
    <div className="m-2">
      <Card key={props.address._id} border="info">
        <Card.Header>{props.address.fullName.toUpperCase()}</Card.Header>
        <Card.Body>
          <Card.Title>{props.address.fullName.toUpperCase()}</Card.Title>
          <Card.Text>{props.address.addressLine1},</Card.Text>
          <Card.Text>{props.address.townCity},</Card.Text>
          <Card.Text>
            {props.address.state.toUpperCase()}-
            <span className="m-1">{props.address.pincode}</span>
          </Card.Text>
        </Card.Body>
        {props.btn && (
          <Card.Footer>
            <div
              style={{ cursor: "pointer" }}
              className="text-center btn-outline-info"
              onClick={() => props.handleActionCreater(props.address._id)}
            >
              DELIVER TO THIS ADDRESS
            </div>
          </Card.Footer>
        )}
      </Card>
    </div>
  );
}

export default AddressCard;
