export const RefundQ = [
  {
    id: "0",
    question: "What happens if my payment fails?",
    answer:
      "Every payment made on this website is safe and secure , however, if there is any failure during the payment process our payment partner will return any debited amount within 5-7 working days or as per the bank policy. ",
  },
  {
    id: "1",
    question: "How should I check my delivery status?",
    answer:
      "After placing the order through our website you will receive a text message on your registered e-mail providing you with a traceable link to check the real time status of your delivery. ",
  },
  {
    id: "2",
    question: "Is there a refund policy?",
    answer:
      " No, we do not provide any refunds for the items purchased through our store",
  },
];
