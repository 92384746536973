import React,{ useState,useEffect } from "react";
import { connect ,useDispatch,useSelector} from "react-redux";
import { Row, Col, Table, Card } from "react-bootstrap";
import { Footer } from "../../components/Footer";
import AddressCard from "../../components/Address/addressCard";
import "./revieworder.css";
import { getCartItems,applyCoupon} from "../../actions";
import hillsServerApi from "../../api/hillsServer";
import axios from "axios";
import {Form,Button,FormGroup, Label, Input, FormText } from 'reactstrap';
import {
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
} from "../../actions/types";
import { bindActionCreators } from 'redux'

const loadRazorPay = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    // console.log(script);
    script.onload = () => {
      resolve(true);
    };

    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

function ReviewOrder(props) {

  const [code, setCode] = useState("");
  const [shippingPrice, setShippingPrice] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState({});
  
  const dispatch = useDispatch();
  const coupon = useSelector((state) => state.appliedCoupon);
  useEffect(() => {
    ShippingRate(props.cartWeigth,props);
    console.log(props);
  }, []);


const handleInputChange=(e)=>{
  setCode(e.target.value);
}
//res.data[0].minimumCartAmount<total


const onClickapplyCoupon=()=>{
  dispatch(applyCoupon(props,code));
}


  //Further details like the estimated time of delivery, the rates along with the ids are also shown.
  const ShippingRate = (weight,data) => {
    dispatch({ type: GET_CART_REQUEST });
    const token=localStorage.getItem('shiprocketToken');
    return axios({
      method: "GET",
      url:`https://apiv2.shiprocket.in/v1/external/courier/serviceability?weight=${weight}&cod=0&pickup_postcode=248171&delivery_postcode=${data.cartAddress[0].pincode}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res => {
        console.log(res.data.data.available_courier_companies[0].rate);   
        setShippingPrice(res.data.data.available_courier_companies[0].rate);
        dispatch({
          type: GET_CART_SUCCESS,
          payload: {
            shippingCharegs:res.data.data.available_courier_companies[0].rate,
            amount:res.data.data.available_courier_companies[0].rate+props.amount,
            cart:props.cartItems,
            sumTotal:props.total,
            myorders: props.orders,
            cartWeightTotal:props.cartWeigth,
            cartlen: props.cartLength,
          },
        });
    })
    .catch(err => {
      console.log(err.message);
    })
  };


  const displayRazorPay = async () => {
    const res = await loadRazorPay(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("SDK failed to load are you online");
      return;
    }
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
      "token"
    );
    
    const result = await hillsServerApi.post(`/payment/razorpay?cartTotal=${props.amount}`, {
      cartWeightTotal: props.cartWeigth,
    });

    if(!result) {
      alert("Server error. Are you online?");
      return;
    }
    var options = {
      key: "rzp_live_nYQIYu4BZWN3Dn",
      currency: result.data.currency,
      name: "Hills2Home",
      description: "Test Transaction",
      image:
        "https://api.startupindia.gov.in/sih/api/file/user/image/Startup?fileName=a5eccbbe-1c2a-4f0d-9df8-36546211216b.jpg",
      order_id: result.data.id,

      prefill: {
        name: props.cartAddress[0].fullName,
        email: props.userInfo.email,
        contact: props.cartAddress[0].mobileNumber,
      },

      handler: async (response) => {
         //alert(response.razorpay_order_id);
      
        const data = await hillsServerApi.post("/users/saveVerifiedOrder", {
          orderID: response.razorpay_order_id,
          paymentID: response.razorpay_payment_id,
          coupon : coupon,
        })
        if (data) {
          props.history.push({
            pathname: "/success",
            state: {
              success: "success",
              orderID: response.razorpay_order_id,
              paymentID: response.razorpay_payment_id,
              cartTotal: props.amount,
              productDetails: props.cartItems,
              coupon : coupon,
            },
          });
        } else {
          props.history.push("/");
        }
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const renderCartItem = (product) => {
    return (
      <tr key={product._id}>
        <td>
          <div className="font-weight-normal">{product.title}</div>
          <div className="font-weight-light">{product.category}</div>
        </td>
        <td className="text-center align-middle">{product.price}</td>
        <td className="text-center align-middle">{product.quantity}</td>
        <td className="text-center align-middle">
          {product.quantity * product.price}
        </td>
      </tr>
    );
  };

  return (
    <>
    <div className="revieworder">
      <p className="display-4 font-weight-light text-left pt-4">Review Order</p>
      <span className="text-left font-weight-light">
        By clicking on the 'Place Your Order and Pay' button,you agree to
        Hills2Home.in's privacy notice and conditions of use.
      </span>
      <Row className="mb-5">
        <Col md={9} sm={12}>
          <h2>Delivery Address : </h2>

          {props.cartAddress[0] && (
            <AddressCard address={props.cartAddress[0]} />
          )}
          <Card body className="mt-5" style={{ textAlign: "left" }}>
            <h2>Delivery date : 4-5 Days</h2>
            <Table hover responsive="md" size="sm">
              <thead>
                <tr>
                  <th className="text-center">ITEM</th>
                  <th className="text-center">PRICE</th>
                  <th className="text-center">QUANTITY</th>
                  <th className="text-center">TOTAL</th>
                  <th className="text-center"></th>
                </tr>
              </thead>
              <tbody>
                {props.cartItems &&
                  props.cartItems.map((product) => renderCartItem(product))}
              </tbody>
            </Table>
          </Card>
        </Col>
        <Col
          md={3}
          sm={12}
          style={{ backgroundColor: "lightgray" }}
          className="mt-4 p-3"
        >
          <Row className="px-5">
            <p style={{fontSize:"20px"}}>Order Summary</p>
          </Row>
          <Row className="text-center">
            <p className="font-weight-normal">
              Shipping and additional costs are calculated based on values you
              have entered.
            </p>
          </Row>
          <Table hover striped bordered variant="light">
            <tbody>
              <tr>
                <td style={{ width: "50%" }}>Order Subtotal</td>
                <td className="text-right" style={{ width: "50%" }}>
                  ₹ {props.total}
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Shipping Charges</td>
                <td className="text-right" style={{ width: "50%" }}>
                  ₹ {shippingPrice}
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Coupon Price</td>
                <td className="text-right" style={{ width: "50%" }}>
                  ₹ {(props.amount)-((props.total)+(shippingPrice))}
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Total</td>
                <td className="text-right" style={{ width: "50%" }}>
                  <b>₹ {props.amount}</b>
                </td>
              </tr>
            </tbody>
          </Table>
          <Form >
          <FormGroup>
            {/* <Label for="exampleEmail">Email</Label> */}
            <Input type="text" name="code" value={code} onChange={handleInputChange} placeholder="Enter Coupon Code" />
          </FormGroup>
          <center><Button color="secondary" size="sm" onClick={onClickapplyCoupon}>Check</Button></center>
          </Form>
          <br/>
          <div className="text-center mb-3">
            <Button
              onClick={displayRazorPay}
              //disabled={props.cartlength > 0 ? false : true}
              size="sm"
              color="primary"
            >
              PLACE YOUR ORDER AND PAY
            </Button>
          </div>
        </Col>
      </Row>
    </div>
    <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    cartAddress: state.user.cartAddress,
    cartItems: state.cart.cartItems,
    total: state.cart.total,
    amount: state.cart.totalAmount,
    cartWeigth: state.cart.cartWeigth,
    userInfo: state.auth.user,
    cartlength: state.cart.cartLength,
  };
};
function MapDispatchToProps(dispatch){
  return bindActionCreators({getCartItems,applyCoupon},dispatch); 
}


export default connect(mapStateToProps,MapDispatchToProps)(ReviewOrder);
