import {
    GET_CATEGORY_REQUEST,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_DATA_REQUEST,
    GET_CATEGORY_DATA_SUCCESS
  } from "./types";
import hillsServerApi from "../api/hillsServer";
import { showSuccessSnackbar, showErrorSnackbar } from "./messageAction";

export const getCategory = () => {
  return async (dispatch) => {
    dispatch({ type: GET_CATEGORY_REQUEST });
    try {
      const response = await hillsServerApi.get(`/category`);
      dispatch({ type: GET_CATEGORY_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch(showErrorSnackbar(error.message));
    }
  };
};

export const getCategoryById = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_CATEGORY_DATA_REQUEST });
    try {
      const response = await hillsServerApi.get(`/category/${id}`);
      dispatch({
        type: GET_CATEGORY_DATA_SUCCESS,
        payload: response.data.data.category,
      });
    } catch (error) {
        dispatch(showErrorSnackbar(error.message));
    }
  };
};
