import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearMessages } from "../actions";
import { Row, Col, Toast } from "react-bootstrap";
import { MdError, MdCheckCircle } from "react-icons/md";

function ShowNotification() {
  const dispatch = useDispatch();

  const { message, successSnackbarOpen, errorSnackbarOpen } = useSelector(
    (state) => state.message
  );

  function handleClose() {
    dispatch(clearMessages());
  }
  const style = {
    position: "fixed",
    bottom: "8%",
    left: "50%",
    transform: "translate(-50%)",
    zIndex: "990",
    borderRadius: "5px",
    minWidth:"300px"
  };
  return (
    <div>
      <Toast
        style={style}
        onClose={handleClose}
        show={successSnackbarOpen || errorSnackbarOpen || false}
        delay={3000}
        autohide
        className={`${
          (successSnackbarOpen && "bg-success") ||
          (errorSnackbarOpen && "bg-danger ") ||
          ""
        } text-white`}
      >
        <Row className="align-items-center m-0">
          <Col xs={1} className="align-items-center">
            {(successSnackbarOpen && (
              <MdCheckCircle size="20" color="white" />
            )) ||
              (errorSnackbarOpen && <MdError size="20" color="white" />)}
          </Col>
          <Col>
            <span
              className="text-center"
              style={{
                fontFamily: "Helvetica",
                fontSize: "0.875rem",
                letterSpacing: "0.01071rem",
                lineHeight: "1.43",
              }}
            >
              {message}
            </span>
          </Col>

          <Col xs={1}>
            <button
              onClick={handleClose}
              type="button"
              className="close"
              aria-label="Close"
            >
              <span aria-hidden="true" className="text-white font-weight-light">
                &times;
              </span>
            </button>
          </Col>
        </Row>
      </Toast>
    </div>
  );
}

export default ShowNotification;
