import React from "react";
import { Container, Accordion, Card } from "react-bootstrap/";
import {TabPane} from 'reactstrap';
export default function FaqTemplate({ data }) {
  const style = {
    padding: "30px 0",
  };

  return (
    <Container>
      <div style={style}>
        <h1 className="text-center pb-3">Frequently Asked Questions</h1>
        <Accordion>
          {data.map((item) => {
            return (
              <Card key={item.id}>
                <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                  <i className="dropdown icon"></i>
                  {item.question}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={item.id}>
                  <Card.Body>{item.answer}</Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>
      </div>
    </Container>
  );
}
