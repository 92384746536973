import {
    GET_COUPON_REQUEST,
    GET_COUPON_SUCCESS,
    GET_COUPON_DATA_REQUEST,
    GET_COUPON_DATA_SUCCESS,
    GET_CART_REQUEST,
    GET_CART_SUCCESS,    
  } from "./types";
import hillsServerApi from "../api/hillsServer";
import { showSuccessSnackbar, showErrorSnackbar } from "./messageAction";

export const getCoupon = () => {
  return async (dispatch) => {
    dispatch({ type: GET_COUPON_REQUEST });
    try {
      const response = await hillsServerApi.get(`/coupon`);
      dispatch({ type: GET_COUPON_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch(showErrorSnackbar(error.message));
    }
  };
};


//export const applyCoupon = (code) => {
//  return async (dispatch) => {
//    //dispatch({ type: GET_COUPON_DATA_REQUEST });
//    try {
//      const response = await hillsServerApi.get(`/coupon/${id}`);
//      dispatch({
//        type: GET_COUPON_DATA_SUCCESS,
//        payload: response.data.data.category,
//      });
//    } catch (error) {
//        dispatch(showErrorSnackbar(error.message));
//    }
//  };
//
//
//
//
//  var utc = new Date().toJSON().slice(0,10).replace(/-/g,'/');  
//  if(coupon.startdate.getTime() <= utc.getTime() <= coupon.enddate.getTime()||coupon.active||coupon.minimumCartAmount<total){
//    
//
//    
//
//  }else{
//    dispatch(showErrorSnackbar("Invalid Coupon"));
//  }
//
//};

export const applyCoupon=(data,code)=>{
console.log(data);
    return async (dispatch) => {
      dispatch({ type: GET_CART_REQUEST });
      dispatch({ type: GET_COUPON_DATA_REQUEST });
      try {
        const res = await hillsServerApi.get(`/coupon/search/by?code=${code}`);
        console.log(res.data);
        var isCouponUsed=false;
        var i;
        for (i = 0; i < data.userInfo.verifiedOrders; i++) {
          try{
            const order = await hillsServerApi.get(`/VerifiedOrder/${data.userInfo.verifiedOrders[i]}`);
            console.log(order.data);
              if(order.data.coupon.code===code){
                isCouponUsed=true;
              }else{
                continue;
              }
          }catch(error){
            console.log(error);
          }
        }
          var utc = new Date(); 
          if(res.data.startdate <= utc <= res.data.enddate||res.data.active||!isCouponUsed){
            var amount = data.amount-res.data.amount;
            var appliedCoupon=res.data;
            dispatch({
              type: GET_CART_SUCCESS,
              payload: {
                amount,
                sumTotal:data.total,
                cartWeightTotal:data.cartWeigth,
                cartlen: data.cartLength,
                cart:data.cartItems,
              },
            });
            dispatch({
              type: GET_COUPON_DATA_SUCCESS,
              payload: {
                appliedCoupon,
              },
            });
            dispatch(showSuccessSnackbar("Coupon Applied"));
          }else{
            dispatch(showErrorSnackbar("Coupon Invalid"));
          }           
      } catch (error) {
        dispatch(showErrorSnackbar(error.message));
      }
    };
  }



export const getCouponById = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_COUPON_DATA_REQUEST });
    try {
      const response = await hillsServerApi.get(`/coupon/${id}`);
      dispatch({
        type: GET_COUPON_DATA_SUCCESS,
        payload: response.data.data.category,
      });
    } catch (error) {
        dispatch(showErrorSnackbar(error.message));
    }
  };
};
