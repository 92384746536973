import React from "react";
import { FaStar } from "react-icons/fa";

const Rating = ({ value, text, color }) => {
  const renderStars = (x) => {
    return (
      <span key={x}>
        <i style={{ color }}>
          <FaStar size="20px" />
        </i>
      </span>
    );
  };
  return (
    <div className="rating">
      {[...Array(value).keys()].map((x) => renderStars(x))}
      <span>{text && text}</span>
    </div>
  );
};

Rating.defaultProps = {
  color: "#f8e825",
};

export default Rating;
