//This js file is for the slider part
//Used code from react-bootstrap carousel component
import React from "react";

//Importing component from react-bootstrap libray
import { Carousel } from "react-bootstrap";

//Imports for images used
import carousel1 from "../../../assets/carousel/carousel2.jpg";
import carousel2 from "../../../assets/carousel/carousel3.jpg";
import carousel3 from "../../../assets/carousel/carousel4.jpg";
import carousel4 from "../../../assets/carousel/1.jpg";
import carousel5 from "../../../assets/carousel/2.jpg";
import carousel6 from "../../../assets/carousel/3.jpg";
import carousel7 from "../../../assets/carousel/4.jpg";
import carousel8 from "../../../assets/carousel/5.jpg";
import carousel9 from "../../../assets/carousel/carousel1.jpg";

//imports for CSS file
import "./carousal.css";

function Slider() {
  return (
    <>
      <Carousel slide={false} className="carousal">
        <Carousel.Item>
          <img className="d-block w-100 " src={carousel1} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={carousel2} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={carousel3} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={carousel4} alt="Fourth slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 " src={carousel5} alt="Fifth slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={carousel6} alt="Sixth slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={carousel7} alt="Seventh slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={carousel8} alt="Eight slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={carousel9} alt="Eight slide" />
        </Carousel.Item>
      </Carousel>
    </>
  );
}

export default Slider;
