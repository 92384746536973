import React from "react";
import { Carousel } from "react-responsive-carousel";
import "./partner.css";
import "react-alice-carousel/lib/alice-carousel.css";
import Amazon from "./images/amazon.png";
import CTD from "./images/CTD.png";
import ExportersindiaLogo from "./images/exportersindia-logo.png";
import Flipkart from "./images/flipkart.png";
import Hillbyte from "./images/hillbyte.png";
import IndMart from "./images/ind mart.png";
import KumaunLogo from "./images/kumaun logo.png";
import AliceCarousel from 'react-alice-carousel';

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 3 },
];

const responsive = {
  0: { items: 1 },
  550: { items: 2 },
  768: { items: 3 },
  1200: { items: 3 },
}



function PartnerSlider() {
  return (
    <div className="slider">
    <br/>
      <h2 className="heading text-center">Our Partners</h2>
      <AliceCarousel
          responsive={responsive}
          autoPlayInterval="5000"
          autoPlayDirection="rtl"
          autoPlay
          fadeOutAnimation={true}
          mouseTrackingEnabled={true}
        // enableAutoPlay autoPlaySpeed={1500}
        >
        <div className="item-img" style={{backgroundColor:"#ffffff"}}>
          <a href="https://www.amazon.in/s?k=HILLCARE&dc&ref=a9_asc_1" target="_black"><center><img src={Amazon} alt="" className="item"></img></center></a>
        </div>
        <div className="item-img" style={{backgroundColor:"#ffffff"}}>
        <a href="http://www.ctdsess.in/" target="_black"><center><img src={CTD} alt="" className="item"></img></center></a>
        </div>
        <div className="item-img" style={{backgroundColor:"#ffffff"}}>
        <a href="https://www.exportersindia.com/hills2home-pvt-ltd/" target="_black"><center><img src={ExportersindiaLogo} alt="" className="item"></img></center></a>          
        </div>
        <div className="item-img" style={{backgroundColor:"#ffffff"}}>
        <a href="https://www.flipkart.com/search?q=Hillcare+&otracker=search&otracker1=search&marketplace=FLIPKART&as-show=on&as=off&augment=false" target="_black"><center><img src={Flipkart} alt="" className="item"></img></center></a>          
        </div>
        <div className="item-img" style={{backgroundColor:"#ffffff"}}>
        <a href="https://hillbytes.com/" target="_black"><center><img src={Hillbyte} alt="" className="item"></img></center></a>
        </div>
        <div className="item-img" style={{backgroundColor:"#ffffff"}}>
        <a href="https://www.indiamart.com/hills2home/profile.html" target="_black"><center><img src={IndMart} alt="" className="item"></img></center></a>          
        </div>
        <div className="item-img" style={{backgroundColor:"#ffffff"}}>
        <a href="https://www.kunainital.ac.in/" target="_black"><center><img src={KumaunLogo} alt="" className="item"></img></center></a>          
        </div>
      </AliceCarousel>
    </div>
  );
}

export default PartnerSlider;

