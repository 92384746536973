import React from "react";
//Importing all the components required here
import { Footer } from "../../components/Footer";
import { Card } from "react-bootstrap";
import acc1 from "../../assets/accr/acc1.jpg";
import acc2 from "../../assets/accr/acc2.jpg";
import acc3 from "../../assets/accr/acc3.jpg";
import acc4 from "../../assets/accr/acc4.png";
import acc5 from "../../assets/accr/acc6.png";
import { Row, Col, Container } from "react-bootstrap";


import { SectionContainer } from "../../components/section";
// import "../index.css";

function Accreditation() {
  /*const titleAccreditation = {
    marginTop: "6%",
    textAlign: "center",
    fontSize: "3.75rem",
    fontWeight: "300",
    fontFamily: "Roboto, sans-serif",
  };*/
  const style = {
    boxShadow:
      "0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)",
    margin: "20px auto",
    maxWidth: "700px",
  };
  return (
    <>
      <SectionContainer>
        <div className="container bg-light">
          <Row className="mx-auto w-50 text-center" style={{paddingLeft:"12%"}}>
              <h1 style={{zIndex:"1",color:"#000000d5",fontWeight:"600"}}>Accreditation</h1>
          </Row>
          <Card style={style}>
            <img src={acc1} className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="card-text">
                Ministry of Commerce &amp; Industry, Government of India which
                is responsible for the formulation and implementation of
                industrial policy and strategies for industrial development has
                recognized us as a registered startup working towards uplifting
                socio-economic conditions of the people residing in the hilly
                terrains of the country.
              </p>
            </div>
          </Card>
          <Card style={style}>
            <img src={acc2} className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="card-text">
                In our zeal to contribute towards young organic farmers, we
                partnered with S.B.S. Government P.G. College Rudrapur to
                organize an e-workshop on Conservation and Cultivation of
                Organic Medicinal plants, The workshop was attended by young
                minds, academicians, scientists and industry experts making it a
                huge success towards our goal of helping the community.
              </p>
            </div>
          </Card>

          <Card style={style}>
            <img src={acc3} className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="card-text">
                We are a registered startup under Startup Uttarakhand, Govt. of
                Uttarakhand right from our formation in 2017. Right from our
                inclusion in the startup Uttarakhand ecosystem, we have played a
                vital part in every event organized under their banner specially
                for the growth of Agriculture and self-help groups.
              </p>
            </div>
          </Card>
          <Card style={style}>
            <img src={acc4} className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="card-text">
                Ashutosh Kandpal CEO and Founder Hill2Home has undergone
                Agripreneur Development Program organised by the prestigious
                Indian Agricultural Research Institute (ICAR) on post harvest
                technologies and food processes for horticulture crops.ICAR is
                the largest network of agricultural research and education
                institutes in the world..
              </p>
            </div>
          </Card>

          <Card style={style}>
            <img src={acc5} className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="card-text">
                Hills2Home is amongst the top 100 startups in India as awarded
                by IIM Calcutta Innovation Park in partnership with Department
                of Science and Technology – Government of India in the esteemed
                Smart Fifty Contest. Smartfifty is an initiative aligned with
                the country’s focused approach to promote entrepreneurship
                driven social development.
              </p>
            </div>
          </Card>
        </div>
      </SectionContainer>
      <Footer />
    </>
  );
}

export default Accreditation;
