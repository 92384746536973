import {
    GET_CATEGORY_REQUEST,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_DATA_REQUEST,
    GET_CATEGORY_DATA_SUCCESS
  } from "../actions/types";
  
const initState = {
  categories: [],
  loading: false,
};

const categoryReducers = (state = initState, action) => {
    switch (action.type) {
      case GET_CATEGORY_REQUEST:
      case GET_CATEGORY_DATA_REQUEST: {
        return {
          ...state,
          loading: true,
        };
      }
  
      case GET_CATEGORY_SUCCESS: {
        return {
          ...state,
          categories: action.payload,
          loading: false,
        };
      }
  
      case GET_CATEGORY_DATA_SUCCESS: {
        return {
          ...state,
          category: action.payload,
          loading: false,
        };
      }

      default:
        return state;
    }
  };
  
  export default categoryReducers;
  