import React, { useEffect } from "react";
import { Router,BrowserRouter, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";

import { isUserLoggedIn } from "./actions";

import { NavBar } from "./components/navbar";
import { Footer } from "./components/Footer";

import ScrollToTop from "./components/ScrollToTop";
import PageNotFound from "./components/PageNotFound";
import ForgetPassword from "./components/Auth/ForgetPassword";

import Accreditation from "./views/Accreditation";
import Faq from "./views/Faq";
import Refund from "./views/Refund";
import Home from "./views/HomePage/Home";
import TermsNConditions from "./views/TnC";
import UserAuth from "./views/Authenticate/UserAuthenticate";
import Address from "./views/Address";
import ContactPage from "./views/Contact";
import OrderSuccess from "./views/Success";
import ResetPassword from "./views/ResetPassword";
import Privacy from "./views/Privacy";
import ReviewOrder from "./views/ReviewOrder";
import Shop from "./views/Shop";
import NGOShop from "./views/NGOShop";
import Product from "./views/ProductDetailsPage";
import Cart from "./views/Cart";
import Orders from "./views/MyOrders";
import BlogPage from "./views/BlogPage";
import Blog from "./views/Blog";
import Dashboard from "./views/UserDashboard";

import history from "./config/history";
import ProtectedRoute from "./components/ProtectedRoute";

import ShowNotification from "./components/ShowNotification";

function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (!auth.isauthenticated) {
      dispatch(isUserLoggedIn());
    }
  }, [auth.isauthenticated, dispatch]);

  return (
    <>
      <ShowNotification />
      <Router history={history}>
        <ScrollToTop />
        <NavBar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/accreditation" component={Accreditation} />
          <Route path="/blog" exact component={Blog} />
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/blog/:id" component={BlogPage} />
          <Route path="/shop" exact component={Shop} />
          <Route path="/shop/ngo" component={NGOShop} />
          <Route path="/product/:id" component={Product} />
          <Route path="/contact" component={ContactPage} />

          <Route path="/authenticate" component={UserAuth} />
          <Route path="/forgetPassword" component={ForgetPassword} />
          <Route path="/resetPassword" component={ResetPassword} />

          <Route path="/refund" component={Refund} />
          <Route path="/faq" component={Faq} />
          <Route path="/privacy-policy" component={Privacy} />
          <Route path="/terms-and-conditions" component={TermsNConditions} />

          <ProtectedRoute path="/myOrders" component={Orders} />
          <ProtectedRoute path="/cart" component={Cart} />
          <ProtectedRoute path="/address" component={Address} />
          <ProtectedRoute path="/reviewOrder" component={ReviewOrder} />
          <ProtectedRoute path="/success" component={OrderSuccess} />

          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
