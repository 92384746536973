export const FaqData = [
  {
    id: "0",
    question: "How do we give back to the farmers?",
    answer:
      "We operate on a system where an advance payment is made to the farmer for growing the organic produce that will be later used to make our product. Another payment is made in the due course of time  to cover the initial cost of organic fertilizers and seeds.  We also give seeds to our new/partner farmers at subsidized rates  and later buy the organic produce from them to manufacture our products.",
  },
  {
    id: "1",
    question:
      "What are the safety measures adopted during the production and packing of the food items?",
    answer:
      "Raw materials are directly procured from the farmlands after going through a established process of organic quality checks,  health standards. In the warehouses they are categorically separated and subjected to nutritional checks. The whole process is completed with high precautionary standards and safe packaging while keeping the goodness of hills intact.   ",
  },
  {
    id: "2",
    question:
      "What precautions are being taken by Hills2Home during the coronavirus pandemic ? ",
    answer:
      "Compulsory wearing of masks and gloves, regular sanitization, frequent health checks  of all the associates linked with us, right from our farmers, our procurement and production people, packaging and delivery team. ",
  },
  {
    id: "3",
    question:
      "Who holds the authority over the product displayed on our website?",
    answer:
      "There are two types of products on our website, one being sold by our brand and the other under “The social entrepreneurship”  program where we promote goods from self help groups and NGOs located in hills. The name of the seller is mentioned below every product.",
  },
  {
    id: "4",
    question: "What are the delivery charges ? ",
    answer:
      "Our delivery charges vary depending on the quantity of items being ordered as well as the distance from our warehouses. ",
  },
  {
    id: "5",
    question: "What is the expiry date of different  products ?",
    answer:
      "Every product carries a specific expiry date which is directly mentioned on the label of the product.",
  },
  {
    id: "6",
    question: "Are the products FSSAI attested?",
    answer:
      "Yes , we have been recognised by Food Safety and Standards Authority of India as well as  Start-up India. Please check our “ Accreditations” tab on the website.",
  },
  {
    id: "7",
    question: "What are the reviews of our previous customers ?",
    answer:
      "You can check the specific product related reviews on our Shop page. Other than that, we have a customer feedback post every Monday on our social media handles including Linkedin and Facebook. ",
  },
];
