import React, { useEffect } from "react";
import { Row, Col, Table, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { delCartItem, getCartItems } from "../../actions";
import "./cart.css";
import { Footer } from "../../components/Footer";
import { useDispatch } from "react-redux";
import { signInShiprocket} from "../../actions";


function Cart({
  cartItems,
  total,
  shipCharge,
  amount,
  loggedIn,
  getCartItems,
  delCartItem,
  history,
}) {
  
  const dispatch = useDispatch();

  useEffect(() => {
    if (loggedIn) {
      getCartItems();
      dispatch(signInShiprocket());
    }
  }, [loggedIn, getCartItems]);

  const renderCartItem = (product) => {
    return (
      <tr key={product._id}>
        <td>
          <div className="font-weight-normal">{product.title}</div>
          <div className="font-weight-light">{product.category}</div>
        </td>
        <td className="text-center align-middle">{product.price}</td>
        <td className="text-center align-middle">{product.quantity}</td>
        <td className="text-center align-middle">
          {product.quantity * product.price}
        </td>
        <td className="align-middle">
          <Button
            style={{ background: "#474f68" }}
            color="primary"
            onClick={() => {
              delCartItem(product._id);
            }}
          >
            <span>&times;</span>
          </Button>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="cart mb-5" style={{ minHeight: "90vh" }}>
        <p className="display-4 font-weight-light text-center p-4">
          SHOPPING CART
        </p>
        <Row>
          <Col md={9} sm={12}>
            <Table hover responsive="md" size="sm">
              <thead>
                <tr>
                  <th>ITEM</th>
                  <th className="text-center">PRICE</th>
                  <th className="text-center">QUANTITY</th>
                  <th className="text-center">TOTAL</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {cartItems &&
                  cartItems.map((product) => renderCartItem(product))}
              </tbody>
            </Table>
          </Col>
          <Col md={3} sm={12} style={{ backgroundColor: "lightgray" }}>
            <Row>
              <h4 className="heading-3">Order Summary</h4>
            </Row>
            <Row>
              <h6 className="text-uppercase font-weight-normal">
                Shipping and additional costs are calculated based on values you
                have entered.
              </h6>
            </Row>
            <Row>
              <Table variant="light">
                <tbody>
                  <tr>
                    <td style={{ width: "50%" }}>Order Subtotal</td>
                    <td className="text-right" style={{ width: "50%" }}>
                      ₹ {total}
                    </td>
                  </tr>
                  <br/><br/>
                </tbody>
              </Table>
            </Row>
            <Row>
              <div className="mx-auto">
                <Button
                  onClick={() => {
                    history.push("/address");
                  }}
                  className="primary white--text"
                >
                  Choose Delivery Address
                </Button>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    cartItems: state.cart.cartItems,
    total: state.cart.total,
    shipCharge: state.cart.shippingCharegs,
    amount: state.cart.totalAmount,
    loggedIn: state.auth.isauthenticated,
  };
};
export default connect(mapStateToProps, { getCartItems, delCartItem })(Cart);
