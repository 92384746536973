import React from "react";
import { Row, Col } from "react-bootstrap";
import { IconContext } from "react-icons";
import { FaEnvelope } from "react-icons/fa";
import { FiPhone} from "react-icons/fi";
import { IoLocation } from "react-icons/io5";

function Address() {

  return (
    <>
      <IconContext.Provider
        value={{
          color: "white",
          size:'1.5em'
        }}
      >
        <Row className="address align-middle">
          <div className="pl-3">
            <IoLocation
              style={{ filter: "drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7))" }}
            />
            {/*<span className="pl-3">Address:</span>*/}
          </div>
          <Col className="pl-3">
            <p style={{textAlign:"left" }}><span className="font-weight-bold">Regd. office:</span> Shivpuri,Jawahar Jyoti, Damuadhunga, Haldwani, Nainital, UK, India, 263139</p>
          </Col>
        </Row>
        <Row className="address align-middle">
          <div className="pl-3">
            <IoLocation
              style={{ filter: "drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7))" }}
            />
            {/*<span className="pl-3">Address:</span>*/}
          </div>
          <Col className="pl-3">            
            <p style={{textAlign:"left" }}><span className="font-weight-bold">Branch office:</span> C 204, First floor , Geeta Complex , Indira Nagar, Dehradun 248171</p>
          </Col>
        </Row>
        <Row className="phone">
          <div className="pl-3">
            <FiPhone
              style={{ filter: "drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7))" }}
            />
            <span className="pl-3 font-weight-bold">TelePhone:</span>
            <a className="pl-3" href="tel:01352641222">
              01352641222
            </a>
          </div>
        </Row>
        <br/>
        <Row className="email">
          <div className="pl-3">
            <FaEnvelope
              style={{ filter: "drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7))" }}
            />
            <span className="pl-3 font-weight-bold">Email:</span>
            <a className="pl-3" href="mailto:info@hills2home.com">
              info@hills2home.com
            </a>
          </div>
        </Row>
      </IconContext.Provider>
      
    </>
  );
}

export default Address;
