import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCT_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCT_SUCCESS,
  SHOW_CATEGORY,
} from "./types";
import hillsServerApi from "../api/hillsServer";
import { showSuccessSnackbar, showErrorSnackbar } from "./messageAction";

function checkIfReviewExists(product, username) {
  let bool = false;
  for (let i = 0; i < product.review.length; i++) {
    if (product.review[i].userName === username) {
      bool = true;
    }
  }
  return bool;
}
export const getProducts = () => {
  return async (dispatch) => {
    dispatch({ type: GET_PRODUCTS_REQUEST });
    try {
      const res = await hillsServerApi.get("/product");
      const data = res.data.data.product;
      dispatch({ type: GET_PRODUCTS_SUCCESS, payload: data });
    } catch (error) {
        dispatch(showErrorSnackbar(error.message));
    }
  };
};

export const getProductById = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_PRODUCT_REQUEST });
    try {
      const res = await hillsServerApi.get(`/product/${id}`);
      const data = res.data.data.product;

      if (getState().auth.user) {
        const name = getState().auth.user.name;

        //Checks if the user has already given the review or not by comparing the name in the review
        const reviewExists = checkIfReviewExists(data, name);
        dispatch({
          type: GET_PRODUCT_SUCCESS,
          payload: { data, reviewExists },
        });
      } else {
        dispatch({
          type: GET_PRODUCT_SUCCESS,
          payload: { data, reviewExists: false },
        });
      }
    } catch (error) {
      dispatch(showErrorSnackbar(error.message));
    }
  };
};

export const showOnlyCategoryProducts = (category) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SHOW_CATEGORY });
      const response = await hillsServerApi.get(
        `/product?category=${category}`
      );
      dispatch({
        type: GET_PRODUCTS_SUCCESS,
        payload: response.data.data.product,
      });
    } catch (e) {
      dispatch(showErrorSnackbar(e.message));
    }
  };
};
