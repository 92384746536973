import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getBlogs } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import ShowLoading from "../../components/Loading";
import { Footer } from "../../components/Footer";
import BlogTemplate from "./BlogTemplate";


function Blog() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBlogs());
  }, [dispatch]);

  const blogs = useSelector((state) => state.blog.blogs) || [];
  const loading = useSelector((state) => state.blog.loading);

  return (
    <>
    <Container style={{ minHeight: "80vh" }}>
      {loading ? (
        <ShowLoading />
      ) : (
        <div className="mb-1">
          <h2 className="heading mt-4">Organic Sundays : Let's unwind the natural way</h2>
          <p className="text-center mb-4">A collection of stories of the harmonious relationships between
            people and hill ecosystems.</p>
          
          <Row>
              {blogs.length > 0 &&
                blogs.map((blog) => {
                  return (
                    <Col key={blog._id} md={6} sm={12} xs={12} lg={4}>
                      <BlogTemplate blog={blog} />
                    </Col>
                  );
                })}
          </Row>
        </div>
      )}
    </Container>
    <Footer />
    </>
  );
}

export default Blog;
