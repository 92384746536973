import React from "react";
import { Spinner } from "react-bootstrap";
function ShowLoading() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%)",
  };
  return (
    <div style={style} className="spinner-container">
      <Spinner animation="grow" />
      <Spinner animation="grow" />
      <Spinner animation="grow" />
      <Spinner animation="grow" />
      <Spinner animation="grow" />
    </div>
  );
}

export default ShowLoading;
