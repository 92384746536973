import React, { useEffect } from "react";
import { Container, Card, Table, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getCartItems } from "../../actions";
import {TabPane} from 'reactstrap';

const Orders = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCartItems());
  }, [dispatch]);

  const myorders = useSelector((state) => state.cart.orders) || [];
  const formatDate = (date) => {
    if (date) {
      const d = new Date(date);
      return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} `;
    }
    return "";
  };

  return (
    <TabPane tabId="3">
    <Container style={{ minHeight: "70vh" }} className="my-3 mb-5">
      <Row>
        <Col xs={12}>
          <h4 className="display-4 text-left">My Orders</h4>
        </Col>
        <Col xs={12}>
          <p className="font-weight-light text-left">
            Incase of discrepancy please contact our Customer Service
          </p>
        </Col>
      </Row>
      <br/><br/>
      {!myorders.length > 0 && (
        <Container>
          <h3 className="text-monospace">No Orders yet</h3>
        </Container>
      )}
      {myorders.length > 0 &&
        myorders.map((order, index) => {
          return (
            <div key={order._id} className="py-3">
              <Card
                style={{
                  margin: "10px 0",
                }}
                border="secondary"
              >
                <div>
                  <Table striped hover responsive="sm">
                    <thead>
                      <tr>
                        <th>ITEM</th>
                        <th>PRICE</th>
                        <th>QUANTITY</th>
                        <th>TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.products.map((item, index) => {
                        return (
                          <tr key={item._id}>
                            <td>{item.title}</td>
                            <td>Rs {item.price}</td>
                            <td>{item.itemDuplicates}</td>
                            <td>{item.price * item.itemDuplicates}</td>
                            <td></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>

                <Row>
                  <Col md>
                    <div style={{ padding: "20px" }}>
                      <Card>
                        <Card.Header>
                          <h4 className="m-0 p-0">Shipped Address</h4>
                        </Card.Header>
                        <Card.Body>
                          <Card.Title>
                            {order.address[0].fullName.toUpperCase()}
                          </Card.Title>
                          <div>
                            <Card.Text>
                              {order.address[0].addressLine1},
                            </Card.Text>
                            <Card.Text>
                              {order.address[0].state}-{" "}
                              {order.address[0].pincode},
                            </Card.Text>
                          </div>
                          <div>
                            <Card.Text>
                              {order.address[0].addressLine2},
                            </Card.Text>
                            <Card.Text>
                              {order.address[0].mobileNumber}
                            </Card.Text>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                  <Col md>
                    <div style={{ padding: "20px" }}>
                      <div>
                        <div>
                          <b>Total Price: ₹ {order.cartTotal}</b>
                        </div>
                        <div>
                          <b>orderID:</b> {order.orderID}
                        </div>
                        {/* <div>
                          <b>paymentID:</b> {order.paymentID}
                        </div> */}
                        <div>
                          <b>createdAt:</b> {formatDate(order.createdAt)}
                        </div>
                        <div>
                          <b>Order Status:</b> <b>{order.status}</b>
                        </div>
                      </div>
                      <br />
                    </div>
                  </Col>
                </Row>
              </Card>
            </div>
          );
        })}
    </Container>
    </TabPane>
  );
};

export default Orders;
