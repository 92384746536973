import React from "react";

import { Container } from "react-bootstrap";

//Importing the CSS file here
import "./about.css";

function About() {
  return (
    <>
        <Container id="about" className="about">
            <div className="text-wrapper p-2" style={{textAlign:"center"}}>
                <h2 className="heading">About Us</h2>
                <p>Hills2Home is a startup striving to connect the hills of the state of Uttarakhand, India, with the markets across the world. Agriculture supports more than 50% of the population in Uttarakhand and therefore it is necessary to develop it to full potential.
                We provide an e-commerce platform to the farmers in the hills to sell their products with ease.</p>
                <p>In addition, we also strive to create a knowledge base to improve the efficiency of agriculture in the hills by educating farmers about the modern techniques of farming.
                The state and central government have created multiple schemes for the benefit of the farmers, but not all farmers are aware of it, therefore the efforts of the government are rendered ineffective. We endeavor to bridge that knowledge gap.
                Through our platform we promote profitable agricultural products (organic products) and enable value addition right in the hills.</p>
                <p>We also work with NGOs to enable the women in the hills to contribute actively to the economic growth.
                Our aim is to promote the unique products and offerings of the hills to strengthen the farmers and revive agriculture.</p>
            </div>
        </Container>
    </>
);
}

export default About;
