import React from "react";
import { Accordion, Card, Container } from "react-bootstrap";

export default function RefundTemplate({ data }) {
  const style = {
    padding: "30px 0",
  };
  const ContainerStyle = {
    minHeight: "70vh",
  };
  return (
    <Container style={ContainerStyle}>
      <div style={style}>
        <h1 className="text-center pb-3">Refund Policy</h1>

        <Accordion>
          {data.map((item) => {
            return (
              <Card key={item.id}>
                <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                  <i className="dropdown icon"></i>
                  {item.question}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={item.id}>
                  <Card.Body>{item.answer}</Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>
      </div>
    </Container>
  );
}
