import React, { useState,useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {getCategory } from "../../actions";

function Category(props) {
  const [selectedOption, setSelectedOption] = useState("");

  const { showOnlyCategoryProducts, getProducts } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  const categories = useSelector((state) => state.category.categories) || [];

  const onValueChange = (e) => {
    if (e.target.value === "all") {
      getProducts();
    } else {
      showOnlyCategoryProducts(e.target.value);
    }
    setSelectedOption(e.target.value);
    // console.log(e.target.value);
  };

  const renderInput = () => {
    //console.log(options);
    return categories.map((option) => {
      return (
        <>
          <option key={option.id} value={option.name}> 
            {option.name} 
          </option> 
        </> 
       );
     });
  };

  return (
    <Row className="mt-4">
      <Col xs={12}>
        <label className="m-0 mr-3 float-right">
          <select
            className="form-control"
            value={selectedOption}
            onChange={onValueChange}
          >
            <option value="none" hidden>
              Select Category
            </option>
            <option key={0} value="all">
              All
            </option>
            {/* {console.log()}; */}
            {renderInput()}
          </select>
        </label>
      </Col>
    </Row>
  );
}

export default Category;
