import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Footer } from "../../components/Footer";
import { SectionContainer } from "../../components/section";
import { resetPassword } from "../../actions";
import "./reset.css";
//import {TabPane} from 'reactstrap';


function ResetPassword(props) {
  const query = new URLSearchParams(props.location.search);
  const token = query.get("resetToken");

  const [formData, setformData] = useState({
    password: "",
    passwordConfirm: "",
    token: token,
  });

  const handleOnChange = (event) => {
    setformData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const OnSubmit = () => {
    //call the action creater/ axios
    props.resetPassword(formData);
  };
  const { register, errors, watch, handleSubmit } = useForm({
    mode: "onChange",
  });
  const passwordRef = useRef({});
  passwordRef.current = watch("password", "");
  return (
    
    <SectionContainer>
      <div className="reset-password-container">
        <div className="container">
          <div className="form-container">
            <form
              className="form "
              onSubmit={handleSubmit(OnSubmit)}
              noValidate
            >
              <h1 className="form-title">Reset Password</h1>

              <input
                ref={register({
                  required: "You must specify a password",
                  minLength: {
                    value: 8,
                    message: "Password must have 5+ characters",
                  },
                  validate: {
                    minlen: (v) =>
                      (v && v.length >= 8) ||
                      "Password must have 5+ characters",
                    UpperCase: (v) =>
                      /(?=.*[A-Z])/.test(v) ||
                      "Must have one uppercase character",
                    aDigit: (v) => /(?=.*\d)/.test(v) || "Must have one number",
                    specialChar: (v) =>
                      /([!@$%])/.test(v) ||
                      "Must have one special character [!@#$%]",
                  },
                })}
                name="password"
                type="password"
                placeholder="Password"
                autoComplete="off"
                onChange={handleOnChange}
                className={
                  errors?.password ? "is-invalid form-control" : "form-control"
                }
              />
              <div className="invalid-feedback">
                {errors?.password?.message}
              </div>

              <input
                ref={register({
                  validate: (value) =>
                    value === passwordRef.current ||
                    "The passwords do not match",
                })}
                name="passwordConfirm"
                type="password"
                placeholder="Confirm Password"
                autoComplete="off"
                onChange={handleOnChange}
                className={
                  errors?.passwordConfirm
                    ? "is-invalid form-control"
                    : "form-control"
                }
              />
              <div className="invalid-feedback">
                {errors?.passwordConfirm?.message}
              </div>
              <button className="form-button">Reset Password</button>
            </form>
          </div>
        </div>
      </div>
    </SectionContainer>
    
  );
}

export default connect(null, { resetPassword })(ResetPassword);
