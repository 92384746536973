import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { Footer } from "../../components/Footer";
import Rating from "../../components/Rating";
import ShowLoading from "../../components/Loading";
import { showOnlyCategoryProducts } from "../../actions";
import "./shop.css";


function NGOShop(props) {
  const { showOnlyCategoryProducts } = props;
  useEffect(() => {
    // Call the action creator
    showOnlyCategoryProducts("ngo");
  }, [showOnlyCategoryProducts]);

  const renderCard = (products) => {
    return products.map((product) => {
      return (
        <div
          className="col-lg-4 col-md-6 mb-4 mb-lg-3 align-self-center"
          key={product._id}
        >
          <div className="card rounded">
            <div style={{ minHeight: "200px" }}>
              <Image
                fluid
                height="350px"
                className="d-block rounded card-img-top"
                src={product.photo[0]}
                alt="Not available"
              />
            </div>
            <div className="card-body">
              <h5 className="text-dark text-center card-title m-0">
                {product.title}
              </h5>
              <div className="card-image-overlay mx-auto text-center">
                <Rating value={Math.ceil(product.averageReviewScore)} />
              </div>
              <div className="row justify-content-between">
                {/* <p className="small text-muted font-italic m-0 my-1 ml-2">
                  {product.category}
                </p> */}
                <div className="ml-2">Price: ₹ {product.price}</div>
              </div>
              <div className="overflow-auto" style={{ height: "180px" }}>
                <p
                  style={{
                    fontSize: ".875rem",
                    fontWeight: "400",
                    lineHeight: "1.375rem",
                    letterSpacing: ".007em",
                  }}
                >
                  {product.description}
                </p>
              </div>
            </div>
            <Link
              to={`/product/${product._id}`}
              className="btn btn-outline-success"
            >
              Buy
            </Link>
          </div>
        </div>
      );
    });
  };
  return (
    <>
    <div className="container shop h-100">
      {props.isloading ? (
        <ShowLoading />
      ) : (
        <div className="row" id="ads">
          {props.products.length > 0? renderCard(props.products):<h1><center>Available Soon</center></h1>}
        </div>
      )}
    </div>
    <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return { products: state.product.products, isloading: state.product.loading };
};
export default connect(mapStateToProps, {
  showOnlyCategoryProducts,
})(NGOShop);
