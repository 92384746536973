import React from "react";
import { Container } from "react-bootstrap";
import { Contact } from "../../components/contact";
import { SectionContainer } from "../../components/section";
import { Footer } from "../../components/Footer";


function ContactPage() {
  return (
    <>
    <SectionContainer>
      <Container>
        <Contact />
      </Container>
    </SectionContainer>
    <Footer />
    </>
  );
}

export default ContactPage;
