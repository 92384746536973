import React, { useState } from "react";
import { connect } from "react-redux";
import { forgetPassword } from "../../actions";
import { useForm } from "react-hook-form";


function ForgetPassword({ forget, setForget, forgetPassword }) {
  const [resetEmail, setResetEmail] = useState("");
  const { register, errors, handleSubmit } = useForm({
    mode: "onChange",
  });

  const handleOnSubmit = (event) => {
    //call the action creater/ axios
    forgetPassword(resetEmail);
    // setForget(!forget);
  };
  return (
    <>
      <div className="form-container reset-container">
        <form className="form" noValidate>
          <h1 className="form-title">Reset Password</h1>

          <input
            ref={register({
              required: "Email is required",
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Invalid Email",
              },
            })}
            name="email"
            type="email"
            placeholder="Email"
            onChange={(e) => {
              setResetEmail(e.target.value);
            }}
            className={
              errors?.email ? "is-invalid form-control" : "form-control"
            }
          />
          <div className="invalid-feedback">{errors?.email?.message}</div>

          <button
            type="submit"
            onClick={handleSubmit(handleOnSubmit)}
            className="form-button"
          >
            Reset Password
          </button>
        </form>
      </div>
    </>
  );
}

export default connect(null, { forgetPassword })(ForgetPassword);
