import {
  GET_BLOG_REQUEST,
  GET_BLOG_SUCCESS,
  GET_BLOG_DATA_REQUEST,
  GET_BLOG_DATA_SUCCESS,
} from "../actions/types";

const initState = {
  blogs: [],
  blog: {},
  loading: false,
};
const blogReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_BLOG_REQUEST:
    case GET_BLOG_DATA_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_BLOG_SUCCESS: {
      return {
        ...state,
        blogs: action.payload,
        loading: false,
      };
    }

    case GET_BLOG_DATA_SUCCESS: {
      return {
        ...state,
        blog: action.payload,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default blogReducers;
