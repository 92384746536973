import React,{useEffect,useState} from 'react'
import "./Dashboard.css";
import $ from "jquery";
import classnames from 'classnames';
import { Container, Row, Col,ListGroup, ListGroupItem, TabContent,TabPane} from 'reactstrap';
import {ExpandMore} from '@material-ui/icons';
import Dashboardicon from '@material-ui/icons/Dashboard';
import { Link } from 'react-router-dom';
import Orders from "../MyOrders";
import Address from "../Address";
import Privacy from "../Privacy";
import Refund from "../Refund";
import Faq from "../Faq";
import ResetPassword from "../ResetPassword";
import TermsNConditions from "../TnC";
import { Footer } from "../../components/Footer";
import HomeLogo from "../../assets/home-logo.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../../actions";
import {Close,Menu} from '@material-ui/icons';

const Dashboard=()=> {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('3');
  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }


useEffect(() => {
  $(".sidebar-dropdown > a").on("click",function() {
    $(".sidebar-submenu").slideUp(200);
    if (
      $(this)
        .parent()
        .hasClass("active")
    ) {
      $(".sidebar-dropdown").removeClass("active");
      $(this)
        .parent()
        .removeClass("active");
    } else {
      $(".sidebar-dropdown").removeClass("active");
      $(this)
        .next(".sidebar-submenu")
        .slideDown(200);
      $(this)
        .parent()
        .addClass("active");
    }
    });
    $("#close-sidebar").on("click",function() {
      $(".page-wrapper").removeClass("toggled");
    });
    $("#show-sidebar").on("click",function() {
      $(".page-wrapper").addClass("toggled");
    });

  }, [])


  const handleLogout = () => {
    window.location.href="/"
  }


  
  const tooglerem=()=>{

    $(".page-wrapper").removeClass("toggled");
  }
  const toogleadd=()=>{

    $(".page-wrapper").addClass("toggled");
  }

    return (
      <>
      <div className="page-wrapper chiller-theme toggled">
        <a style={{marginTop:"50px"}} id="show-sidebar" className="btn btn-sm btn-dark" href="#" onClick={(e) => {e.preventDefault();toogleadd()}}>
          <Menu/>
        </a>
        <nav id="sidebar" className="sidebar-wrapper">
          <div className="sidebar-content">
            <div className="sidebar-brand">
              <Link to="/">
                <img src={HomeLogo} width="59px" height="59px" alt="" />
              </Link>
              <a href="#" onClick={(e) => {e.preventDefault()}}>Hills2Home</a>
              <div id="close-sidebar" onClick={tooglerem}>
                <Close/>
              </div>
            </div>
            
            <div className="sidebar-header">
              <div className="user-pic">
                <img className="img-responsive img-rounded" src="http://edtech.hct.ac.ae/files/2019/05/userimg.png" alt="User picture" />
              </div>
              <div className="user-info">
                <span className="user-name">
                </span>
                  <span className="user-role">{auth.user.name.toUpperCase()}</span>
                <span className="user-status">
                  <i className="fa fa-circle" />
                  <span>Online</span>
                </span>
              </div>
            </div>
            
            <div className="sidebar-menu">
              <ul>
                <li className="header-menu">
                  <span>General</span>
                </li>
                <li>
                  <Link to="/cart">
                    <i className="fa fa-book" />
                    <span>Your Cart</span>
                  </Link>
                </li>             
                <li className="header-menu">
                  <span>Extra</span>
                </li>
                <li>
                  <a href="#" className={classnames({ active: activeTab === '3' })} onClick={(e) => {e.preventDefault(); toggle('3'); }}>
                    <i className="fa fa-book" />
                    <span style={{fontWeight:"normal"}}>My Order</span>
                  </a>
                </li>
                <li>
                  <a href="#" className={classnames({ active: activeTab === '4' })} onClick={(e) => {e.preventDefault(); toggle('4'); }}>
                    <i className="fa fa-calendar" />
                    <span style={{fontWeight:"normal"}}>My Address</span>
                  </a>
                </li>
                <li>
                  <a href="#" className={classnames({ active: activeTab === '5' })} onClick={(e) => {e.preventDefault(); toggle('5'); }}>
                    <i className="fa fa-caft" />
                    <span style={{fontWeight:"normal"}}>Refund Policy</span>
                  </a>
                </li>
                <li>
                  <a href="#" className={classnames({ active: activeTab === '6' })} onClick={(e) => {e.preventDefault(); toggle('6'); }}>
                    <i className="fa fa-caft" />
                    <span style={{fontWeight:"normal"}}>Privacy Policy</span>
                  </a>
                </li>
                <li>
                  <a href="#" className={classnames({ active: activeTab === '7' })} onClick={(e) => {e.preventDefault(); toggle('7'); }}>
                    <i className="fa fa-caft" />
                    <span style={{fontWeight:"normal"}}>FAQs</span>
                  </a>
                </li>
                <li>
                  <a href="#" className={classnames({ active: activeTab === '8' })} onClick={(e) => {e.preventDefault(); toggle('8'); }}>
                    <i className="fa fa-caft" />
                    <span style={{fontWeight:"normal"}}>Terms & Conditions</span>
                  </a>
                </li>
                
                <li>
                  <a href="#" onClick={(e) => {e.preventDefault(); dispatch(logout());handleLogout() }}>
                    <i className="fa fa-folder" />
                    <span>Logout</span>
                  </a>
                </li>
              </ul>
            </div> 
          </div>
        </nav>
        
        <main className="page-content">
          <TabContent activeTab={activeTab} style={{marginRight:"40px",minHeight:"500px"}}>
            {/* <UserProfile/> */}
            <Orders/>
            <Address/>
            <Refund/>
            <Privacy/>
            <Faq/>
            <TermsNConditions/>
            {/* <UserAccount/> */}
            {/* <Cart/> */}
            {/* <UpdateProfile/> */}
            {/* <ResetPassword/> */}
          </TabContent>
        </main>
        <Footer/>
      </div>
      
        </>
      );
}

export default Dashboard
