import React, { useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../actions";

function DropDownMenu(props) {
  const [menuOpen, toggleMenuOpen] = useState(false);
  const dispatch = useDispatch();
  return (
    <NavDropdown
      onMouseEnter={() => {
        toggleMenuOpen(true);
      }}
      onMouseLeave={() => {
        toggleMenuOpen(false);
      }}
      show={menuOpen}
      title={props.title}
      id="collasible-nav-dropdown"
    >
      <NavLink
        to="/myOrders"
        className="dropdown-item"
        onClick={props.handleOnClick}
      >
        My Orders
      </NavLink>
      <NavLink
        to="/address"
        className="dropdown-item"
        onClick={props.handleOnClick}
      >
        My Addresses
      </NavLink>
      <NavLink
        to="/refund"
        className="dropdown-item"
        onClick={props.handleOnClick}
      >
        Refund Policy
      </NavLink>
      <NavLink
        to="/privacy-policy"
        className="dropdown-item"
        onClick={props.handleOnClick}
      >
        Privacy Policy
      </NavLink>
      <NavLink
        to="/faq"
        className="dropdown-item"
        onClick={props.handleOnClick}
      >
        FAQs
      </NavLink>
      <NavLink
        to="/terms-and-conditions"
        className="dropdown-item"
        onClick={props.handleOnClick}
      >
        Terms &amp; Conditions
      </NavLink>
      <NavDropdown.Divider />
      <NavDropdown.Item
        className=""
        onClick={() => {
          dispatch(logout());
          props.handleOnClick();
        }}
      >
        Logout
      </NavDropdown.Item>
    </NavDropdown>
  );
}

export default DropDownMenu;
