import {
    GET_COUPON_REQUEST,
    GET_COUPON_SUCCESS,
    GET_COUPON_DATA_REQUEST,
    GET_COUPON_DATA_SUCCESS
  } from "../actions/types";
  
const initState = {
  appliedCoupon: {},
  loading: false,
};

const couponReducers = (state = initState, action) => {
    switch (action.type) {
      
      case GET_COUPON_DATA_REQUEST: {
        return {
          ...state,
          loading: true,
        };
      }
     
      case GET_COUPON_DATA_SUCCESS: {
        return {
          ...state,
          appliedCoupon: action.payload.appliedCoupon,
          loading: false,
        };
      }

      default:
        return state;
    }
  };
  
  export default couponReducers;
  