import {
  GET_USER_ADDRESS_REQUEST,
  GET_USER_ADDRESS_SUCCESS,
  ADD_USER_ADDRESS_SUCCESS,
  ADD_DELIVERY_ADDRESS_SUCCESS,
  ADD_REVIEW_SUCCESS,
} from "./types";

import hillsServerApi from "../api/hillsServer";
import history from "../config/history";
import { getProductById } from "./productAction";
import { showSuccessSnackbar, showErrorSnackbar } from "./messageAction";

export const getAddress = () => {
  return async (dispatch) => {
    dispatch({ type: GET_USER_ADDRESS_REQUEST });
    try {
      const res = await hillsServerApi.get("/users/address");
      if (res.data.status === "success") {
        dispatch({
          type: GET_USER_ADDRESS_SUCCESS,
          payload: res.data.data.address,
        });
      }
    } catch (error) {
      dispatch(showErrorSnackbar(error.message));
    }
  };
};

export const addUserAddress = (addrDetails) => {
  return async (dispatch) => {
    try {
      const res = await hillsServerApi.patch(`/users/address`, {
        address: addrDetails,
      });
      if (res.data.status === "success") {
        // console.log(res);
        dispatch({
          type: ADD_USER_ADDRESS_SUCCESS,
          payload: res.data.data.user.address,
        });
      } 
    } catch (error) {
      dispatch(showErrorSnackbar(error.message));
    }
  };
};

export const addDeliveryAddress = (addressId) => {
  return async (dispatch, getstate) => {
    var shipaddress = getstate().user.address.find(
      (addr) => addr._id === addressId
    );
    try {
      const res = await hillsServerApi.patch(`/users/cartaddress`, {
        shipToCartAddress: shipaddress,
      });
      if (res.data.status === "success") {
        // console.log(res);
        dispatch({
          type: ADD_DELIVERY_ADDRESS_SUCCESS,
          payload: res.data.data.user.cartAddress,
        });
        history.push("/reviewOrder");
      } 
    } catch (error) {
      dispatch(showErrorSnackbar(error.message));
    }
  };
};

export const addReviewAction = (Review, id) => {
  return async (dispatch) => {
    try {
      const res = await hillsServerApi.post(`/product/addReview`, {
        review: Review,
        productID: id,
      });
      if (res) {
        dispatch(showSuccessSnackbar("Review Posted"));
        dispatch(getProductById(id));
        dispatch({ type: ADD_REVIEW_SUCCESS });
      }
    } catch (error) {
      dispatch(showErrorSnackbar(error.message));
    }
  };
};
