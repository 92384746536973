import { combineReducers } from "redux";
import authReducers from "./authReducers";
import cartReducers from "./cartReducers";
import productReducers from "./productReducers";
import userReducers from "./userReducers";
import messageReducers from "./messageReducer";
import blogReducers from "./blogReducers";
import categoryReducers from "./categoryReducers";
import couponReducers from "./couponReducers";

export default combineReducers({
  auth: authReducers,
  cart: cartReducers,
  product: productReducers,
  user: userReducers,
  message: messageReducers,
  blog: blogReducers,
  category: categoryReducers,
  coupon: couponReducers,
});
