import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Modal, Form } from "react-bootstrap";

const AddressModal = ({ addUserAddress }) => {
  const [show, setShow] = useState(false);
  const [addressData, setaddressData] = useState({
    fullName: "",
    mobileNumber: "",
    pincode: "",
    // isValid: true,
    addressLine1: "",
    addressLine2: "",
    townCity: "",
    state: "",
    landMark: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOnChange = (event) => {
    setaddressData({
      ...addressData,
      [event.target.name]: event.target.value,
    });
  };

  const OnSubmit = (event) => {
    setShow(false);
    //call the action creater/ axios
    addUserAddress(addressData);
  };

  const { register, errors, handleSubmit } = useForm({
    mode: "onChange",
  });
  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        Add Address
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Form.Title>Add Address</Form.Title>/ */}
          <Form noValidate>
            <Form.Group controlId="formName">
              <Form.Control
                placeholder="Full Name:*"
                name="fullName"
                ref={register({ required: "Fullname is required" })}
                onChange={handleOnChange}
                className={
                  errors?.fullName ? "is-invalid form-control" : "form-control"
                }
              />
              <div className="invalid-feedback">
                {errors?.fullName?.message}
              </div>
            </Form.Group>

            <Form.Group controlId="formPhoneNumber">
              <Form.Control
                placeholder="Mobile Number:*"
                name="mobileNumber"
                ref={register({
                  required: "Mobile Number is required",
                  pattern: {
                    value: /^[6-9]\d{9}$/,
                    message: "Please enter a valid Indian Phone Number",
                  },
                })}
                onChange={handleOnChange}
                className={
                  errors?.mobileNumber
                    ? "is-invalid form-control"
                    : "form-control"
                }
              />
              <div className="invalid-feedback">
                {errors?.mobileNumber?.message}
              </div>
            </Form.Group>
            <Form.Group controlId="formZip">
              <Form.Control
                placeholder="Pincode:*"
                name="pincode"
                ref={register({
                  required: "Pincode is required",
                  pattern: {
                    value: /^[1-9][0-9]{5}$/,
                    message: "Please enter a valid Pincode",
                  },
                })}
                onChange={handleOnChange}
                className={
                  errors?.pincode ? "is-invalid form-control" : "form-control"
                }
              />
              <div className="invalid-feedback">{errors?.pincode?.message}</div>
            </Form.Group>
            <Form.Group controlId="formAddress1">
              <Form.Control
                placeholder="Flat, House no., Building, Company, Apartment:*"
                name="addressLine1"
                ref={register({ required: "Address Line 1 is required" })}
                onChange={handleOnChange}
                className={
                  errors?.addressLine1
                    ? "is-invalid form-control"
                    : "form-control"
                }
              />
              <div className="invalid-feedback">
                {errors?.addressLine1?.message}
              </div>
            </Form.Group>

            <Form.Group controlId="formAddress2">
              <Form.Control
                placeholder="Area, Colony, Street, Sector, Village:*"
                name="addressLine2"
                ref={register({ required: "Address Line 2 is required" })}
                onChange={handleOnChange}
                className={
                  errors?.addressLine2
                    ? "is-invalid form-control"
                    : "form-control"
                }
              />
              <div className="invalid-feedback">
                {errors?.addressLine2?.message}
              </div>
            </Form.Group>

            <Form.Group controlId="formlandMark">
              <Form.Control
                placeholder="Landmark e.g near apollo hospital:*"
                name="landMark"
                ref={register({ required: "Landmark is required" })}
                onChange={handleOnChange}
                className={
                  errors?.landMark ? "is-invalid form-control" : "form-control"
                }
              />
              <div className="invalid-feedback">
                {errors?.landMark?.message}
              </div>
            </Form.Group>

            <Form.Group controlId="formCity">
              <Form.Control
                placeholder="Town/City:*"
                name="townCity"
                ref={register({ required: "Town/City is required" })}
                onChange={handleOnChange}
                className={
                  errors?.townCity ? "is-invalid form-control" : "form-control"
                }
              />
              <div className="invalid-feedback">
                {errors?.townCity?.message}
              </div>
            </Form.Group>

            <Form.Group controlId="formState">
              <Form.Control
                placeholder="State:*"
                name="state"
                ref={register({ required: "State is required" })}
                onChange={handleOnChange}
                className={
                  errors?.state ? "is-invalid form-control" : "form-control"
                }
              />
              <div className="invalid-feedback">{errors?.state?.message}</div>
            </Form.Group>
          </Form>
          <Form.Text id="requiredMsg" muted>
            *indicates required field
          </Form.Text>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {/* <Form.Button> */}
            <Button variant="primary" onClick={handleSubmit(OnSubmit)}>
              Save
            </Button>
            {/* </Form.Button> */}
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddressModal;
