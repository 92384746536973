import React, { useState } from "react";
import { useForm } from "react-hook-form";

function Login({ signIn, forget, setForget }) {
  const [formData, setformData] = useState({
    email: "",
    password: "",
  });

  const handleOnChange = (event) => {
    setformData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const OnSubmit = () => {
    signIn(formData);
  };

  const { register, errors, handleSubmit } = useForm({
    mode: "onChange",
  });
  return (
    <>
      <div className="form-container sign-in-container">
        <form className="form" onSubmit={handleSubmit(OnSubmit)} noValidate>
          <h1 className="form-title">Sign In</h1>

          <input
            ref={register({
              required: "Email is required",
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Invalid Email",
              },
            })}
            name="email"
            type="email"
            placeholder="Email"
            onChange={handleOnChange}
            className={
              errors?.email ? "is-invalid form-control" : "form-control"
            }
          />
          <div className="invalid-feedback">{errors?.email?.message}</div>

          <input
            ref={register({
              required: "You must specify a password",
              validate: {
                minlen: (v) =>
                  (v && v.length >= 8) || "Password must have 5+ characters",
                UpperCase: (v) =>
                  /(?=.*[A-Z])/.test(v) || "Must have one uppercase character",
                aDigit: (v) => /(?=.*\d)/.test(v) || "Must have one number",
                specialChar: (v) =>
                  /([!@$%])/.test(v) ||
                  "Must have one special character [!@#$%]",
              },
            })}
            name="password"
            type="password"
            placeholder="Password"
            autoComplete="off"
            onChange={handleOnChange}
            className={
              errors?.password ? "is-invalid form-control" : "form-control"
            }
          />
          <div className="invalid-feedback">{errors?.password?.message}</div>

          <button className="form-button">Sign in</button>
          <div onClick={() => setForget(!forget)} className="forget-password">
            Forgot your password ?
          </div>
        </form>
      </div>
    </>
  );
}

export default Login;
