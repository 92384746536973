import {
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  RESET_CART,
  REMOVE_CART_ITEM_SUCCESS,
  SHIPPING_RATE_SUCCESS,
  SHIPPING_RATE_REQUEST
} from "../actions/types";

const initState = {
  loading: false,
  cartItems: [],
  orders: [],
  total: 0,
  shippingCharegs: 0,
  totalAmount: 0,
  cartWeight: 0,
  cartLength: 0,
  reviewAlreadyExists: false,
};

const cartReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_CART_REQUEST: {
      return { ...state, loading: true };
    }

    case GET_CART_SUCCESS: {
      return {
        ...state,
        cartItems: action.payload.cart,
        total: action.payload.sumTotal,
        shippingCharges: action.payload.shippigCharges,
        totalAmount: action.payload.amount,
        orders: action.payload.myorders,
        loading: false,
        cartWeigth: action.payload.cartWeightTotal,
        cartLength: action.payload.cartlen,
      };
    }

    case REMOVE_CART_ITEM_SUCCESS: {
      const newcartitem = state.cartItems.filter(
        (item) => item._id !== action.payload
      );

      return {
        ...state,
        cartItems: newcartitem,
      };
    }
    case RESET_CART: {
      return {
        ...initState,
      };
    }

    default:
      return state;
  }
};
export default cartReducers;
