import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import Rating from "../../components/Rating";
import ShowLoading from "../../components/Loading";
import Category from "./Category";
import { Footer } from "../../components/Footer";
import { getProducts, showOnlyCategoryProducts,getCategory } from "../../actions";
//import { categories } from "./data";
import "./shop.css";


function Shop(props) {
  
  const { getProducts,showOnlyCategoryProducts,getCategory } = props;
  useEffect(() => {
    // Call the action creator
    getProducts();
  }, [getProducts]);

  const renderCard = (products) => {
    return products.map((product) => {
      return (
        <div
          className="col-lg-3 col-md-6 mb-4 mb-lg-3 align-self-center col-sm-6  col-xs-12 "
          key={product._id}
        >
          <div className="card rounded">
            <div style={{minHeight:"200px"}}>
              <Image
                float
                height="230px"
                className="d-block rounded card-img-top"
                src={product.photo[0]}
                alt="Not available"
              />
            </div>
            <div className="card-body">
              <h6 className="text-dark text-center card-title m-0 overflow-auto" style={{ maxHeight: "80px",minHeight:"25px" }}>{product.title}</h6>
              <div className="card-image-overlay mx-auto text-center">
                <Rating value={Math.ceil(product.averageReviewScore)} />
              </div>
              <br/>
              <div className="row justify-content-between">
                <p className="small text-muted font-italic m-0 my-1 ml-2">
                  {product.category}
                </p>
                <div><span style={{fontWeight:"500",fontSize:"15px",color:"green"}}>Price: ₹ {product.price}</span>{" "}{product.fakeprice&&<span style={{fontWeight:"400",fontSize:"12px",color:"red",textDecoration:"line-through"}}>₹ {product.fakeprice}</span>}</div>
              </div>
              {/* <div className="overflow-auto" style={{ height: "180px" }}> */}
                {/* <p */}
                  {/* // style={{ fontSize: ".875rem", fontWeight: "400", lineHeight: "1.375rem", letterSpacing: ".007em"}} */}
                {/* // > */}
                  {/* {product.description} */}
                {/* </p> */}
              {/* </div> */}
            </div>
            <Link
              to={`/product/${product._id}`}
              className="btn btn-outline-success"
            >
              Buy
            </Link>
          </div>
        </div>
      );
    });
  };
  return (
    <>
    <div className="container shop h-100">
      <Category
        showOnlyCategoryProducts={showOnlyCategoryProducts}
        getProducts={getProducts}
        getCategory={getCategory}
      />
      {props.isloading ? (
        <ShowLoading />
      ) : (
        <div className="row" id="ads">
          {props.products.length > 0 && renderCard(props.products)}
        </div>
      )}
    </div>
    <Footer/>
    </>
  );
}

const mapStateToProps = (state) => {
  return { products: state.product.products, isloading: state.product.loading };
};
export default connect(mapStateToProps, { getProducts, showOnlyCategoryProducts,getCategory })(Shop);
