import React, { useState } from "react";
import Login from "../../components/Auth/Login";
import Register from "../../components/Auth/Register";
import Overlay from "../../components/Auth/Overlay";
import ForgetPassword from "../../components/Auth/ForgetPassword";
import { SectionContainer } from "../../components/section";
import { Footer } from "../../components/Footer";
import "./auth.css";
import { connect } from "react-redux";
import { signInAction, signUpAction } from "../../actions";


const UserAuth = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [forget, setForget] = useState(false);

  return (
    <>
    <SectionContainer>
      <div className="auth-container">
        <div
          className={`container ${
            (forget ? `forget-panel-active` : ``) ||
            (expanded ? `right-panel-active` : ``)
          }`}
          id="container"
        >
          <Login forget={forget} setForget={setForget} signIn={props.signIn} />
          <Register signUp={props.signUp} />
          <ForgetPassword setForget={setForget} forget={forget} />
          <Overlay
            setExpanded={setExpanded}
            expanded={expanded}
            forget={forget}
            setForget={setForget}
          />
        </div>
      </div>
    </SectionContainer>
    <Footer />
    </>
  );
};

export default connect(null, {
  signIn: signInAction,
  signUp: signUpAction,
})(UserAuth);
