import React from "react";
import { Contact } from "../../components/contact";
import { Slider } from "../../components/HomePage/carousal";
import { About } from "../../components/HomePage/about";
import { SocialImpact } from "../../components/HomePage/social";
import { PartnerSlider } from "../../components/HomePage/partner";
import { Footer } from "../../components/Footer";
import { SectionContainer } from "../../components/section";

function Home() {
  return (
    <>
      <SectionContainer>
        <Slider />
        <About />
        <SocialImpact />
        <PartnerSlider />
        <Contact btn />
      </SectionContainer>
      <Footer />
    </>
  );
}

export default Home;
