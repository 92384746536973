import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
  createStore, applyMiddleware,
  // compose
} from "redux";

// StyleSheet
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

// Libraries
import thunk from "redux-thunk";

// Components
import App from "./App";
import reducers from "./reducers";

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
const store = createStore(reducers, applyMiddleware(thunk));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
