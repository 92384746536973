import React, { useState } from "react";
import { Form, Col, Button } from "react-bootstrap";

function ContactForm(props) {
  const [formData, setformData] = useState({
    name: "",
    email: "",
    townCity: "",
    subject: "",
    msg: "",
    success: "",
  });

  const handleOnChange = (event) => {
    setformData({
      ...formData,
      [event.target.id]: event.target.value,
    });
  };

  const { email, townCity, msg, subject, name } = formData;

  const emailObject = {
    email: email,
    fullName: name,
    townCity: townCity,
    message: msg,
  };

  return (
    <center>
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        props.handleOnSubmit(emailObject);
      }}
    >
      
      <Form.Group controlId="name">
        <Col md={12}>
          {/*<Form.Label>Enter Your Name *</Form.Label>*/}
          <Form.Control
            size="md"
            type="text"
            placeholder="Name"
            value={name}
            onChange={handleOnChange}
          />
        </Col>
      </Form.Group>

      <Form.Group controlId="email">
        <Col md={12}>
          {/*<Form.Label>Enter Your Email *</Form.Label>*/}
          <Form.Control
            size="md"
            type="email"
            value={email}
            placeholder="Email"
            onChange={handleOnChange}
          />
        </Col>
      </Form.Group>

      {/* <Form.Group controlId="mobile">
        <Col md={10}>
          <Form.Label>Enter Your Phone Number</Form.Label>
          <Form.Control
            size="sm"
            type="text"
            value={mobile}
            placeholder="Phone No."
            onChange={handleOnChange}
          />
        </Col>
      </Form.Group> */}
      <Form.Group controlId="townCity">
        <Col md={12}>
          {/*<Form.Label>Enter Your Town or City</Form.Label>*/}
          <Form.Control
            size="md"
            type="text"
            value={townCity}
            placeholder="Town / City"
            onChange={handleOnChange}
          />
        </Col>
      </Form.Group>

      <Form.Group controlId="subject">
        <Col md={12}>
          {/*<Form.Label>Enter Your Subject</Form.Label>*/}
          <Form.Control
            size="md"
            type="text"
            value={subject}
            placeholder="Subject"
            onChange={handleOnChange}
          />
        </Col>
      </Form.Group>

      <Form.Group controlId="msg">
        <Col md={12}>
          <Form.Control
            size="md"
            as="textarea"
            type="text"
            value={msg}
            placeholder="Type your message here..."
            onChange={handleOnChange}
          />
        </Col>
      </Form.Group>
      <Form.Group>
        <Col md={12}>
          <Button
            className="float-left"
            size="md"
            variant="success"
            type="submit"
          >
            Submit
          </Button>
        </Col>
      </Form.Group>
    </Form>
    </center>
  );
}

export default ContactForm;
