import React from "react";
import { Container, Table, Card, Button, Row, Col } from "react-bootstrap";
import { MdCheckCircle } from "react-icons/md";
import { MdInfo } from "react-icons/md";
import { Footer } from "../../components/Footer";

function OrderSuccess(props) {
  if (props.location.state) {
    var {
      success,
      orderID,
      paymentID,
      cartTotal,
      productDetails,
    } = props.location.state;
  }
  return (
    <>
    <Container>
      <Row className="justify-content-center pb-0">
        <Card className="mx-auto mt-5 w-100" border="success">
          <div className="text-center pt-4 pb-3 bg-light">
            {/* <ListGroup variant="flush"> */}
            {success === "success" ? (
              <span className="text-center">
                <MdCheckCircle size="100" color="green" />
              </span>
            ) : (
              <span className="text-center">
                <MdInfo size="100" color="red" />
              </span>
            )}
            <h3 className="text-center heading-2">Payment Information</h3>
          </div>

          <Table className="text-center">
            <tbody className="text-left">
              <tr>
                <td>Payment Status</td>
                <td>{success}</td>
              </tr>
              <tr>
                <td>Order ID</td>
                <td>{orderID}</td>
              </tr>
              <tr>
                <td>Payment ID</td>
                <td>{paymentID}</td>
              </tr>
              <tr>
                <td>Cart Total</td>
                <td>₹ {cartTotal}</td>
              </tr>
            </tbody>
          </Table>

          {/* </ListGroup> */}
        </Card>
      </Row>

      <Row>
        <Col>
          <Row className="justify-content-center">
            <h2 className="heading-2">Cart Items</h2>
          </Row>
          <Row className="justify-content-center">
            {productDetails.map((product) => {
              return (
                <Col md={4} xs={12} className="my-2">
                  <Card
                    key={product._id}
                    className="mx-auto"
                    border="secondary"
                  >
                    <Card.Header>
                      {product.title}{" "}
                      <span class="headline ml-4">
                        <b>X {product.quantity}</b>
                      </span>
                    </Card.Header>
                    <Table>
                      <thead>
                        <tr></tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Price</td>
                          <td>₹ {product.price}</td>
                        </tr>
                        <tr>
                          <td>Brand</td>
                          <td>{product.brandName}</td>
                        </tr>
                        <tr>
                          <td>Manufactured By</td>
                          <td>{product.manufacturedBy}</td>
                        </tr>
                        <tr>
                          <td>Shelf Life</td>
                          <td>{product.shelflife} Days</td>
                        </tr>
                        <tr>
                          <td>Weight</td>
                          <td>{product.weight}g</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>

      <Row>
        <div className="mb-5 mx-auto">
          <Button
            onClick={() => props.history.push("/")}
            className="primary white--text"
          >
            Shop More
          </Button>
        </div>
      </Row>
    </Container>
    <Footer />
    </>
  );
}

export default OrderSuccess;
