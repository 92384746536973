import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import {TabPane} from 'reactstrap';
import AddressModal from "../../components/Address/modal";
import AddressCard from "../../components/Address/addressCard";
import ShowLoading from "../../components/Loading";
//import action creater
import { addUserAddress, getAddress, addDeliveryAddress } from "../../actions";

import "./address.css";
function Address(props) {
  const { getAddress } = props;
  useEffect(() => {
    getAddress();
    // return () => {
    //   cleanup;
    // };
  }, [getAddress]);
  return (
    <TabPane tabId="4">
    <div className="container mb-2 address">
      {props.isloading ? (
        <ShowLoading />
      ) : (
        <>
          <Row className="mb-5">
            <h1 className="pt-4">Select a delivery address</h1>

            <span className="font-weight-light">
              Is the address you'd like to use displayed below? If so, click the
              corresponding "Deliver to this address" button.
              <br />
              Or you can enter a new delivery address.
            </span>
          </Row>
          <Row>
            {props.auth &&
              props.addresses.map((address) => (
                <Col md={4} sm={12} key={address._id}>
                  <AddressCard
                    address={address}
                    btn
                    keys={address._id}
                    handleActionCreater={props.addDeliveryAddress}
                  />
                </Col>
              ))}
          </Row>
          <Row className="my-3">
            <AddressModal addUserAddress={props.addUserAddress} />
          </Row>
        </>
      )}
    </div>
    </TabPane>
  );
}

const mapStateToProps = (state) => {
  return {
    addresses: state.user.address,
    auth: state.auth.isauthenticated,
    isloading: state.user.loading,
  };
};
export default connect(mapStateToProps, {
  addUserAddress,
  getAddress,
  addDeliveryAddress,
})(Address);
