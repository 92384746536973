import {
  GET_BLOG_REQUEST,
  GET_BLOG_SUCCESS,
  GET_BLOG_DATA_REQUEST,
  GET_BLOG_DATA_SUCCESS,
} from "./types";
import hillsServerApi from "../api/hillsServer";
import { showSuccessSnackbar, showErrorSnackbar } from "./messageAction";

export const getBlogById = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_BLOG_DATA_REQUEST });
    try {
      const response = await hillsServerApi.get(`/blog/${id}`);
      dispatch({
        type: GET_BLOG_DATA_SUCCESS,
        payload: response.data.data.blog,
      });
    } catch (error) {
        dispatch(showErrorSnackbar(error.message));
    }
  };
};

export const getBlogs = () => {
  return async (dispatch) => {
    dispatch({ type: GET_BLOG_REQUEST });
    try {
      const response = await hillsServerApi.get(`/blog`);
      dispatch({ type: GET_BLOG_SUCCESS, payload: response.data.data.blogs });
    } catch (error) {
        dispatch(showErrorSnackbar(error.message));
    }
  };
};
