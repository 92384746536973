import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";

function Register({ signUp }) {
  const [formData, setformData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleOnChange = (event) => {
    setformData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleOnSubmit = (event) => {
    const { email, password, username, confirmPassword } = formData;
    //call the action creater/ axios
    signUp({ email, password, passwordConfirm: confirmPassword, name: username });
  };
  const { register, errors, watch, handleSubmit } = useForm({
    mode: "onChange",
  });
  const passwordRef = useRef({});
  passwordRef.current = watch("password", "");
  return (
    <>
      <div className="form-container sign-up-container">
        <form className="form" onSubmit={handleSubmit(handleOnSubmit)} noValidate>
          <h1 className="form-title">Create Account</h1>
          <input
            ref={register({
              required: "Username is required",
            })}
            name="username"
            type="text"
            placeholder="Name"
            onChange={handleOnChange}
            className={
              errors?.username ? "is-invalid form-control" : "form-control"
            }
          />
          <div className="invalid-feedback">{errors?.username?.message}</div>

          <input
            ref={register({
              required: "Email is required",
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Invalid Email",
              },
            })}
            name="email"
            type="email"
            placeholder="Email"
            autoComplete="true"
            onChange={handleOnChange}
            className={
              errors?.email ? "is-invalid form-control" : "form-control"
            }
          />
          <div className="invalid-feedback">{errors?.email?.message}</div>

          <input
            ref={register({
              required: "You must specify a password",
              minLength: {
                value: 8,
                message: "Password must have 5+ characters",
              },
              validate: {
                minlen: (v) =>
                  (v && v.length >= 8) || "Password must have 5+ characters",
                UpperCase: (v) =>
                  /(?=.*[A-Z])/.test(v) || "Must have one uppercase character",
                aDigit: (v) => /(?=.*\d)/.test(v) || "Must have one number",
                specialChar: (v) =>
                  /([!@$%])/.test(v) ||
                  "Must have one special character [!@#$%]",
              },
            })}
            name="password"
            type="password"
            placeholder="Password"
            autoComplete="off"
            onChange={handleOnChange}
            className={
              errors?.password ? "is-invalid form-control" : "form-control"
            }
          />
          <div className="invalid-feedback">{errors?.password?.message}</div>

          <input
            ref={register({
              validate: (value) =>
                value === passwordRef.current || "The passwords do not match",
            })}
            name="confirmPassword"
            type="password"
            placeholder="Confirm Password"
            autoComplete="off"
            onChange={handleOnChange}
            className={
              errors?.confirmPassword
                ? "is-invalid form-control"
                : "form-control"
            }
          />
          <div className="invalid-feedback">
            {errors?.confirmPassword?.message}
          </div>
          <button className="form-button">sign up</button>
        </form>
      </div>
    </>
  );
}

export default Register;
