import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Img404 from "../assets/Scarecrow.png";
import { SectionContainer } from "./section";
function PageNotFound() {
  return (
    <SectionContainer>
      <Container style={{ minHeight: "70vh" }}>
        <Row className="my-auto">
          <Col md sm={12} className="align-self-center">
            <img src={Img404} alt="404ERROR"></img>
          </Col>
          <Col md sm={12} className="align-self-center text-center">
            <h1>I have bad news for you</h1>
            <p className="font-weight-light">
              The page you are looking for might be removed or is temporarily
              unavailable
            </p>
            <Link to="/" className="form-button ">
              BACK TO HOME
            </Link>
          </Col>
        </Row>
      </Container>
    </SectionContainer>
  );
}

export default PageNotFound;
